import { Play } from '../../Play';
import { BoardModel } from '../../ui/BoardModel';
import { GamePlayStrategy } from '../GamePlayStrategy';

export class UnplaceLettersStrategy implements GamePlayStrategy {
    execute(plays: Play[], args: { boardModel : BoardModel } ): boolean {
        plays.forEach(play => {
            if (!play.startCoords) throw new Error("UnplaceLettersStrategy.execute() startCoords is null")
            if (!play.endWordCoords) throw new Error("UnplaceLettersStrategy.execute() endWordCoords is null")

            args.boardModel.getCellsForCoordinates(play.playedRange).forEach((cell) => {
                cell.unplaceLetter()
            })
        })
        return true
    }
}
