import { $ } from '../lib/jQuery';

import { View } from './View';

export class GameView extends View {

    private _ui: JQuery<HTMLElement>;
	
    private _isVisible: boolean;

    constructor() {
        super();
        this.log.setLevel(this.log.levels.INFO)

        this._ui = $('.game-area');
        this._isVisible = this._ui.is(':visible');
    }

    setVisibility(isVisible: boolean) {
        this.log.info(this.constructor.name, '.setVisibility(', isVisible, ')')
        if (isVisible) {
            this._ui.show();
        } else {
            this._ui.hide();
        }
        this._isVisible = isVisible;
    }

    getVisibility(): boolean {
        return this._isVisible;
    }
}
