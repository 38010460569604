import { $, JQuery } from '../lib/jQuery';

import { View } from './View';

export class CreateGameView extends View {
    static CREATE_NEW_GAME = 'CREATE_NEW_GAME';
    static EDIT_BOARD= 'EDIT_BOARD';

    // the id's of the create-game controls
    // the field names must exactly match the field names in the GameTemplate class
    protected readonly CONTROL_NAMES = [
        'boardPath', 'tileGenerator', 'dictionaryPath',
        'gamePlayStrategyName', 'attackRangeStrategyName',
        'startGameScore', 'maxGameScore', 'maxTurns',
    ];

    private _ui: JQuery;
    private _uiSelectControls = new Map<string, JQuery<HTMLElement>>();
    private _uiBoardEditControl : JQuery;

    constructor() {
        super();
        this.log.setLevel(this.log.levels.INFO)

        this._ui = $('div.new-game');

        this.CONTROL_NAMES.forEach((controlId) => {
            this._uiSelectControls.set(controlId, this._ui.find(`#${controlId}`));
        })
        
        this._uiBoardEditControl = this._ui.find('#boardEdit');
        this._uiBoardEditControl
            .off('click')
            .on('click', () => this.handleEditBoard());


        let newGameButton = this._ui.find('button#createGameButton');
        newGameButton
            .off('click')
            .on('click', () => this.handleCreateGame());
    }

    set isVisible(isVisible: boolean) {
        this._ui.toggle(isVisible);
    }


    handleCreateGame() {
        this.log.info(this.constructor.name, '.handleCreateGame()');

        const rules: Record<string, any> = {};
        this._uiSelectControls.forEach((ui : JQuery, key : string) => {
            let val = ui.val() as string;
            rules[key] = ui.attr("type") === "number" ? parseInt(val) : val;
        });
    
        this.emit(CreateGameView.CREATE_NEW_GAME, { rules });
    }

    handleEditBoard(): any {
        let boardPath = this._uiSelectControls.get('boardPath')?.val();
        if (boardPath) {
            this.emit(CreateGameView.EDIT_BOARD, boardPath);
        }
    }


    populateControls( controlSettings: any ) {
        this.log.info(this.constructor.name, `.populateList(...)`);

        this._uiSelectControls.forEach((uiControl, uiControlId) => {
            let controlSettingsForKey = controlSettings[uiControlId];
            if (controlSettingsForKey) {
                uiControl.empty();
                controlSettingsForKey.forEach((value : string, name : string) => {
                    uiControl.append($('<option>', { value }).text(name));
                })
            }
        })
    }
}
