import { Coordinates } from "./Coordinates";

export class Direction {
    readonly value: string;
    private _opposite: Direction | null = null;
    readonly coordinateTransformer: (startCoordinates: Coordinates) => Coordinates;

    private static readonly allDirections: Direction[] = [];

    public static readonly ANY   = new Direction("any", () => { throw new Error("Direction.ANY cannot transform Coordinates"); });
    static { Direction.ANY._opposite = Direction.ANY }

    // ORDINAL DIRECTIONS
    public static readonly N = new Direction("north", coords => ( new Coordinates(coords.row - 1, coords.col)));
    public static readonly S = new Direction("south", coords => ( new Coordinates(coords.row + 1, coords.col)));
    public static readonly E = new Direction("east",  coords => ( new Coordinates(coords.row,     coords.col + 1)));
    public static readonly W = new Direction("west",  coords => ( new Coordinates(coords.row,     coords.col - 1)));
    static {
        Direction.N._opposite = Direction.S; Direction.S._opposite = Direction.N;
        Direction.E._opposite = Direction.W; Direction.W._opposite = Direction.E;
    }

    // CARDINAL DIRECTIONS
    //                                                               new Coordinates( adajacent row, (    if odd     ? odd-rowed col : even-rowed col))
    public static readonly NE = new Direction("northeast", coords => new Coordinates(coords.row - 1, (coords.row % 2 ? coords.col    : coords.col + 1 )))
    public static readonly NW = new Direction("northwest", coords => new Coordinates(coords.row - 1, (coords.row % 2 ? coords.col -1 : coords.col )))
    public static readonly SE = new Direction("southeast", coords => new Coordinates(coords.row + 1, (coords.row % 2 ? coords.col    : coords.col + 1 )))
    public static readonly SW = new Direction("southwest", coords => new Coordinates(coords.row + 1, (coords.row % 2 ? coords.col -1 : coords.col )))
    static {
        Direction.NE._opposite = Direction.SW; Direction.SW._opposite = Direction.NE;
        Direction.NW._opposite = Direction.SE; Direction.SE._opposite = Direction.NW;
    }

    private constructor(value: string, coordinateTransformer: (startCoordinates: Coordinates) => Coordinates) {
        this.value = value;
        this.coordinateTransformer = coordinateTransformer;

        Direction.allDirections.push(this);
    }

    get opposite(): Direction {
        if (this._opposite === null) throw new Error(`Direction ${this} has no opposite`);
        return this._opposite
    }

    static allOrdinalDirections(cellSideCount: 4 | 6 = 4) : Direction[] {
        if (cellSideCount === 4) {
            return [Direction.N, Direction.E, Direction.S, Direction.W];
        } else if (cellSideCount === 6) {
            return [Direction.NE, Direction.E, Direction.SE, Direction.SW, Direction.W, Direction.NW];
        } else {
            throw new Error(`Unsupported cellSideCount: ${cellSideCount}`);
        }
    }

    nextCoordinates(coordinates: Coordinates): Coordinates {
        return this.coordinateTransformer(coordinates);
    }

    static toDirection(obj: any): Direction {
        if ( obj instanceof Direction ) return obj;
        if ( typeof obj === 'string' ) {
            return Direction.allDirections.find(dir => dir.toString() === obj.toLowerCase()) || Direction.ANY
        }
        return Direction.ANY
    }

    public toString(): string {
        return this.value;
    }
}
