import { CompositeStrategy } from './CompositeStrategy';
import { Play } from '../../Play';
import { GamePlayStrategy } from '../GamePlayStrategy';

// apply each ConditionalMetaStrategy rule
//  if all the 'ifTrue' evaluate to true then execute every 'thenDo' strateg(ies)
// @if 
// @then   
// new IfThenStrategy( 
//    { ifTrue : [ new PlayTypeCombinationConditionalStrategy( [ Play.ATTACK, Play.MOVE ] ) ], thenDo : [ new OtherStrategy(), ... ] }, ...
// )

export class IfThenStrategy implements GamePlayStrategy {
    private _ifTrue: GamePlayStrategy;
    private _thenDo: GamePlayStrategy;

    constructor(parameters: {
        ifTrue: GamePlayStrategy | GamePlayStrategy[],
        thenDo: GamePlayStrategy | GamePlayStrategy[]
    }) {
        this._ifTrue = Array.isArray(parameters.ifTrue) ? new CompositeStrategy(parameters.ifTrue) : parameters.ifTrue;
        this._thenDo = Array.isArray(parameters.thenDo) ? new CompositeStrategy(parameters.thenDo) : parameters.thenDo;
    }

    execute(plays: Play[], args: any): boolean {
        if (this._ifTrue.execute(plays, args)) {
            return this._thenDo.execute(plays, args);
        }
        return false;
    }
}
