import { $ } from '../lib/jQuery';

import { Play } from '../Play';
import { GameControllerEvent } from './GameController';
import { EventEmitter } from '../lib/EventEmitter';
import { Game } from '../globals';

export class TurnView {
    private _progressUi: JQuery<HTMLElement>;

    constructor(private playEmitter: EventEmitter) {
        this._progressUi = $('progress.turns')

        playEmitter.on(GameControllerEvent.GAME_INFO, gameInfo => this._receiveGame(gameInfo))
        playEmitter.on(GameControllerEvent.NEW_TURN, playInfo => this._receivePlay(playInfo))
    }


    private _receiveGame(game: Game): void {
        this._progressUi.attr('max', game.rules.maxTurns)
    }

    private _receivePlay(play: Play): void {
        this._progressUi.attr('value', play.turnIndex + 1);
    }
}
