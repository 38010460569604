import { $ } from '../lib/jQuery';

import { View } from './View';

export class StatusView extends View {
    private _uiVersion: JQuery<HTMLElement>;

    constructor() {
        super();
        this._uiVersion = $('div.appStatus span.version')
    }

    setStatus(statusData: any) {
        this._uiVersion.text(
            (statusData.webAppStatus?.sha ?? 'unknown') +
            ' / ' +
            (statusData.apiServerStatus?.version?.sha ?? 'unknown')
        );
		this._uiVersion.prop('title', JSON.stringify(statusData, null, 2)); // hover, mouse-over tooltip text
    }
}
