import { Play } from '../../Play';
import { PlayType } from '../../PlayType';

import { ExtendedOutcome, OutcomeMetaStrategy } from '../metaStrategy/OutcomeMetaStrategy';

export class AttackWinsMetaStrategy extends OutcomeMetaStrategy {

    protected getPlaysByOutcome(plays: Play[]): ExtendedOutcome {

        if (null == plays[0].playType) throw new Error("AttackWinsMetaStrategy.getPlaysByOutcome() playType is null")
        if (null == plays[1].playType) throw new Error("AttackWinsMetaStrategy.getPlaysByOutcome() playType is null")

        let playsByOutcome: any = { _winners: [], _losers: [], winner: null, loser: null };

        if (plays[0].playType === PlayType.ATTACK && plays[1].playType === PlayType.MOVE) {
            playsByOutcome._winners[0] = playsByOutcome.winner = plays[0];
            playsByOutcome._losers[0] = playsByOutcome.loser = plays[1];
        } else if (plays[0].playType === PlayType.MOVE && plays[1].playType === PlayType.ATTACK) {
            playsByOutcome._losers[0] = playsByOutcome.loser = plays[0];
            playsByOutcome._winners[0] = playsByOutcome.winner = plays[1];
        } else {
            throw new Error("AttackWinsMetaStrategy.getPlaysByOutcome() playType is invalid: " + plays[0].playType + " " + plays[1].playType);
        }

        return playsByOutcome as ExtendedOutcome;
    }

}
