
    import { main } from "./src/main.ts";

    const ENV = {
      name: String(import.meta.env.VITE_ENV),
      restBaseUrl: String(import.meta.env.VITE_REST_API_URL),
      authConfig: {
        authorizationParams: {
          audience: String(import.meta.env.VITE_AUTH0_AUDIENCE),
        },
        domain: String(import.meta.env.VITE_AUTH0_ISSUER_URI).replace("https://", "").replace("/", ""),
        clientId: String(import.meta.env.VITE_AUTH0_CLIENT_ID),
        cacheLocation: 'localstorage'
      }
    }

    console.log(JSON.stringify(ENV, null, 2))

    async function start() {
      // The webapp main controller 
      window.mc = await main(ENV) // top-level await is not supported in all browsers
    }
    start()

    // dev mode for CSS/rendering of a test board; uncomment or run in console
    // setTimeout( () =>
    //   mc.__renderTestBoard()
    // , 100)

  