import { log } from './lib/log';

import { Direction } from './Direction';

/**
 * Cube-coordinate type for hexagon-cell maths
 * See: https://www.redblobgames.com/grids/hexagons/
 */
class Cube {
    q: number
    r: number
    s: number
    constructor(q: number, r: number, s: number) {
        this.q = q
        this.r = r
        this.s = s
    }
}


export class Coordinates {
    log: log.Logger;

    readonly row: number;
    readonly col: number;

    private cube: Cube | null; // lazy loaded cubic version of coordinates for hexagon-cell maths

    constructor(_row: number, _col: number) {
        this.log = log.getLogger(this.constructor.name);
        this.log.setLevel(log.levels.SILENT)

        this.row = _row;
        this.col = _col;
    }

    /**
    * Converts an object to a Coordinates object.
    * 
    * @param obj - The object to convert. Should be either a Coordinates object or an object with 'row' and 'col' properties.
    * @returns The converted Coordinates object.
    * @throws If the object cannot be converted to a Coordinates object, throws an Error.
    */
    static toCoordinates(obj: any): Coordinates {
        let c = this.toOptionalCoordinates(obj);
        if (c) {
            return c;
        }
        throw new Error(`Expected: Coordinates(), [row,col], { row: number, col: number } - got: ${obj}`)
    }

    /**
     * Tries to convert an object to a Coordinates object.
     * 
     * @param obj The object to convert. Should be either a Coordinates object or an object with 'row' and 'col' properties.
     * @returns The converted Coordinates object, or null if the object cannot be converted.
     */
    static toOptionalCoordinates(obj: any): Coordinates | null {
        if (!obj) {
            return null;
        } else if (obj instanceof Coordinates) {
            return obj;
        } else if (obj instanceof Array && obj.length === 2) {
            return new Coordinates(obj[0], obj[1])
        } else if (typeof obj === 'string') {
            obj = obj.replace('\s+', '').replace('(', '').replace(')', '')
            return new Coordinates(parseInt(obj.split(',')[0]), parseInt(obj.split(',')[1]))
        } else if (typeof obj === 'object' && 'row' in obj && 'col' in obj) {
            return new Coordinates(obj.row, obj.col)
        } else {
            return null
        }
    }

    /**
     * Converts the Coordinates object to a JSON object.
     * 
     * @returns A JSON object with properties 'row' and 'col' representing the coordinates.
     */
    toJSON(): { row: number, col: number } {
        return {
            row: this.row,
            col: this.col
        };
    }

    /**
     * Converts the Coordinates object to a string.
     * 
     * @returns A string representation of the coordinates in the format "(row,col)".
     */
    toString(): string {
        return `(${this.row},${this.col})`;
    }
    /**
     * Checks if the current Coordinates object is equal to the other Coordinates object.
     * 
     * @param other The other Coordinates object to compare with. Can be null.
     * @returns Returns true if the other Coordinates object is not null and its properties are equal to the current object's properties. Otherwise, returns false.
     */
    equals(other: Coordinates | null): boolean {
        if (!other) return false;
        return (this.row === other.row && this.col === other.col);
    }

    /**
     * Calucate the distance between two cube coordinates, for hexagon-cells maths
     * See: https://www.redblobgames.com/grids/hexagons/#distances
     * @param other destination Coordinates
     * @returns the distance in cell units
     */
    cubeDistanceTo(other: Coordinates): number {
        log.debug(`cubeDistanceTo(${this}, ${other})`)
        let a = this.toCube
        let b = other.toCube
        return (Math.abs(a.q - b.q) + Math.abs(a.r - b.r) + Math.abs(a.s - b.s)) / 2.0
    }

    /**
     * Convert from "even-r axial" coordinates to Cube coordinates, for hexagon-cells maths
     * See: https://www.redblobgames.com/grids/hexagons/#conversions
     * Lazy loads the Cube coordinates
     * @returns Cube coordinates (q, r, s)
     */
    get toCube(): Cube {
        if (this.cube == null) {
            let q = this.col - (this.row + (this.row % 2)) / 2.0
            let r = this.row
            this.cube = new Cube(q, r, -q - r)
        }
        return this.cube
    }

    /**
     * Distance to the destination Coordinates
     * @param other destination Coordinates
     * @param cellSideCount square or hexagon cells
     * @returns the distance in cell units
     */
    distanceTo(other: Coordinates, cellSideCount: 4 | 6): number {
        this.log.info(this.constructor.name, '.distanceTo(.)');

        if (cellSideCount === 4) {
            return Math.sqrt(
                Math.pow(this.row - other.row, 2) +
                Math.pow(this.col - other.col, 2)
            )
        } else if (cellSideCount === 6) {
            return this.cubeDistanceTo(other)
        } else {
            throw new Error('Coordinates.distanceTo() error: cellSideCount not supported')
        }
    }

    /**
     * Returns an array of Coordinates from this to other, inclusive
     * @param other final Coordinates
     * @param direction direction to other coordinates, must be exact
     * @returns array of Coordinates
     */
    coordinateRangeTo(other: Coordinates, direction: Direction): Coordinates[] {
        let range: Coordinates[] = [this];
        let next: Coordinates = this
        while (!next.equals(other)) {
            next = direction.nextCoordinates(next)
            if (!next) throw new Error('Play.coordinateRangeTo() error: did not match end coordinate')
            range.push(next)
            if (range.length > 1000) throw new Error('Play.coordinateRangeTo() Direction incorrect')
        }
        return range
    }
}
