import { CellView } from "./CellView";
import { Coordinates } from "../Coordinates";
import { Direction } from "../Direction";
import { BoardModel } from "./BoardModel";

const DEFAULT_CURRENT_LETTER = ''
const DEFAULT_CELL_DIRECTION = Direction.ANY
const DEFAULT_BONUS_MAP = {
    'word': 1,
    'letter': 1,
}

type BonusType = 'word' | 'letter'

export class CellModel {
    cellView: CellView
    private _boardModel: BoardModel
    readonly coordinates: Coordinates

    private _isStaticLetter: boolean = false
    private _isBlock: boolean = false
    private _hasFlag: boolean = false

    private _bonusType: BonusType | null = null
    private _bonusValueMap: { [key: string]: number } = { ...DEFAULT_BONUS_MAP }

    private _playerIndex: number | null = null
    private _playedPlayerIndex: number | null = null
    private _direction: Direction = DEFAULT_CELL_DIRECTION
    private _isPlaced: boolean = false

    private _currentLetter: string = DEFAULT_CURRENT_LETTER
    private _flagClaims: number[] = []
    private _isPlaceable: boolean = false
    private _isAttackable: boolean = false

    /**
     * Constructor public for test harness; should only be called by static factory methods
     * @param boardModel 
     * @param row 
     * @param column 
     */
    constructor(
        boardModel: BoardModel,
        row: number,
        column: number
    ) {
        this._boardModel = boardModel;
        this.coordinates = new Coordinates(row, column);
    }

    //
    // Cell statically set properites
    //

    get isBlock(): boolean {
        return this._isBlock;
    }

    /**
     * Whether this cell is a flag
     * See *claim methods for flag sub-state
     */
    get hasFlag(): boolean {
        return this._hasFlag;
    }

    /**
     * @return 'letter', 'word', null
     */
    get bonusType(): BonusType | null {
        return this._bonusType
    }

    /**
     * Effective Letter Bonus for this cell. 
     * The letter bonus multiplier, or 
     * No-bonus default is 1
     */
    get letterBonus(): number {
        return this._bonusValueMap['letter']
    }

    /**
     * Effective Word Bonus for this cell.
     * The word bonus mulitplier, or
     * No-bonus default is 1
     */
    get wordBonus(): number {
        return this._bonusValueMap['word']
    }

    get bonusValue(): number {
        if (this._bonusType)
            return this._bonusValueMap[this._bonusType]
        else
            return 0
    }

    /**
     * Remove any bonus on the current cell
     */
    resetBonus() {
        this._bonusValueMap = DEFAULT_BONUS_MAP
    }

    /**
     * Private utitity method to configure initial bonus settings
     * @param bonusType 
     * @param bonusValue 
     */
    private _setBonus(bonusType: BonusType, bonusValue: number) {
        this._bonusType = bonusType
        this._bonusValueMap[bonusType] = bonusValue
        // cellView update not required; _setBonus only called at init
    }

    /**
     * Whether this is a static-letter cell
     * Value of static letter is CellModel.currentLetter
     */
    get isStatic(): boolean {
        return this._isStaticLetter
    }

    /**
     * Set whether a letter tile can currently be placed on this cell,
     * and the direction of Play.
     * 
     * Direction.ANY means not placeable.
     * 
     * @param direction direction of play. 
     */
    setPlaceable(direction: Direction): CellModel {
        this._direction = direction
        this._isPlaceable = (direction !== Direction.ANY)
        this.cellView.updateView()
        return this
    }

    /**
     * whether a letter tile can currently be placed on this cell
     */
    get isPlaceable(): boolean {
        return this._isPlaceable
    }

    /**
     * Whether the cell is within attack range
     */
    get isAttackable(): boolean {
        return this._isAttackable
    }

    /**
     * Set whether the cell can be attacked
     * @param isAttackable 
     */
    set isAttackable(isAttackable: boolean) {
        if (isAttackable !== this._isAttackable) {
            this._isAttackable = isAttackable
            this.cellView.updateView()
        }
    }

    /**
     * Return if there is a player on the cell
     * @returns The playerIndex of the occupying player, or null
     */
    get playerIndex(): number | null {
        return this._playerIndex
    }

    /**
     * Set the player present on the cell
     */
    set playerIndex(value: number | null) {
        this._playerIndex = value
        this.cellView.updateView()
    }

    /**
     * Current cell letter. May be static, placed, played or null.
     */
    get currentLetter(): string | null {
        return this._currentLetter
    }

    /** 
     * whether a letter tile is currently in-play on this cell
     */
    get isPlaced(): boolean {
        return this._isPlaced
    }

    get direction(): Direction {
        return this._direction;
    }

    /**
     * Place the given letter
     * @param letter letter to place
     * @returns Pl
     */
    placeLetter(letter: string): Direction {
        this._currentLetter = letter;
        this._isPlaced = true
        this._isPlaceable = false
        this.cellView.updateView()
        return this._direction;
    }

    unplaceLetter() {
        this._isPlaced = false
        this._direction = DEFAULT_CELL_DIRECTION
        if (!this._isStaticLetter) {
            this._currentLetter = DEFAULT_CURRENT_LETTER
        }
        this.cellView.updateView()
    }

    /**
     * Mark this cell played by the given player
     * @param playerIndex Index of the player
     */
    playCell(playerIndex: number) {
        this._playedPlayerIndex = playerIndex
        this.cellView.updateView()

    }

    /**
     * Whether this cell has been played
     * @returns The playerIndex of the player, null if unplayed
     */
    get playedBy(): number | null {
        return this._playedPlayerIndex
    }


    /**
     * List of claims on this flags
     * @returns playerIndicies of claimaints, may be empty array
     */
    get flagClaims(): number[] {
        return this._flagClaims
    }
    /**
     * Remove any claims on current play
     */
    resetFlagClaim() {
        this._flagClaims = []
        this.cellView.updateView()

    }

    /**
     * Add a claim on this flag
     * @param playerIndex 
     */
    claimFlag(playerIndex: number) {
        if (! this._flagClaims.includes(playerIndex)) {
            this._flagClaims.push(playerIndex)
            this._flagClaims.sort()
        }
        this.cellView.updateView()
    }

    //
    // Geometry queries
    //

    _getAdjacentCoordinates(direction: Direction): Coordinates | undefined {
        return direction.nextCoordinates(this.coordinates)
    }

    getAdjacentCell(direction: Direction): CellModel | null {
        let coordinates = this._getAdjacentCoordinates(direction)
        return (coordinates ? this._boardModel.getCellAtCoordinates(coordinates) : null)
    }

    getAllCellsInDirection(direction: Direction): CellModel[] {
        let cell = this.getAdjacentCell(direction);
        if (cell) {
            let cells = [cell];
            while (cell = cell.getAdjacentCell(direction)) {
                cells.push(cell);
            }
            return cells;
        } else {
            return [];
        }
    }

    /**
     * Static method to generate a board from a string
     * @param boardString The multiline string representing the board
     * @returns 
     */
    static generateBoard(
        boardModel: BoardModel,
        boardString: string
    ): CellModel[][] {
        let board: CellModel[][] = [];

        let columnCount: number | null = null;
        let row = 0;

        let rowStrings = boardString.split('\n');
        for (let rowString of rowStrings) {
            rowString = rowString.replace(/ /g, '').trim();
            if (rowString.length === 0) {
                continue;
            }

            let cellRow = CellModel.generateRow(boardModel, rowString, row++)

            if (columnCount === null) {
                columnCount = cellRow.length;
            } else if (cellRow.length !== columnCount) {
                throw new Error(`Invalid board: row "${row}" is not length ${columnCount}`);
            }

            board.push(cellRow);
        }
        return board;
    }

    private static generateRow(
        boardModel: BoardModel,
        rowString: string,
        row: number
    ): CellModel[] {
        let col: number = 0
        let cellRow: CellModel[] = []
        for (const cellCharacter of rowString) {
            cellRow.push(CellModel.generateCell(boardModel, cellCharacter, row, col++))
        }
        return cellRow;
    }

    private static generateCell(
        boardModel: BoardModel,
        cellCharacter: string,
        row: number,
        col: number
    ): CellModel {
        let cell = new CellModel(boardModel, row, col);

        switch (true) {
            case ('.' == cellCharacter): break;                       // blank cell
            case ('_' == cellCharacter): cell._isBlock = true; break;  // block

            case ('<' == cellCharacter): cell._playerIndex = 0; break; // Player 0
            case ('>' == cellCharacter): cell._playerIndex = 1; break; // Player 1

            case ('!' == cellCharacter): cell._hasFlag = true; break;  // Flag

            case ('3' == cellCharacter): cell._setBonus('letter', 3); break; // 3x letter bonus
            case ('2' == cellCharacter): cell._setBonus('letter', 2); break; // 2x letter bonus
            case ('4' == cellCharacter): cell._setBonus('letter', 4); break; // 4x letter bonus
            case ('@' == cellCharacter): cell._setBonus('word', 2); break; // 2x word bonus
            case ('#' == cellCharacter): cell._setBonus('word', 3); break; // 3x word bonus
            case ('$' == cellCharacter): cell._setBonus('word', 4); break; // 4x word bonus

            case (cellCharacter >= 'A' && cellCharacter <= 'Z'):      // static letter
                cell._isStaticLetter = true;
                cell._currentLetter = cellCharacter;
                break;
            default:
                throw new Error(`Invalid character in board: "${cellCharacter}"`);
        }

        cell.cellView = new CellView(cell);
        return cell;
    }
}
