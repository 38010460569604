import { log } from "../lib/log";

import { Game } from "../globals";
import { GameController } from "./GameController";
import { Play } from "../Play";
import { GameRuleLogic } from "../gameLogic/GameRuleLogic";
import { ButtonsView } from "./ButtonsView";
import { LettersView } from "./LettersView";


/**
 * Get the GameState instances for the GameController
 * @param gc GameController instance
 * @returns an indexed object of instantiated GameStates
 */
export function getGameStates(gc: GameController): { [key: string]: GameState } {
    return {
        NO_GAME: new GameStateNoGame(gc),
        NOT_STARTED: new GameStateWaitForPlayerToJoin(gc),

        ANIMATING: new GameStateAnimating(gc),

        SELECT_FIRST_TILE: new GameStateSelectFirstTile(gc),
        PLACE_FIRST: new GameStatePlaceFirstTile(gc),
        ADDITIONAL_TILE: new GameStateAdditionalTile(gc),

        REMOTE_MOVE: new GameStateRemoteMove(gc),

        LOCAL_WIN: new GameStateLocalPlayerWin(gc),
        REMOTE_WIN: new GameStateRemoteWin(gc),
    }
}

/**
 * Abstract base class for game states
 * Some default handlers are implemented
 */
export abstract class GameState {
    log: log.Logger;
    protected gc: GameController;

    protected SHORT_POLL_MS = 3/* seconds */ * 1000;
    protected LONG_POLL_MS = 30/* seconds */ * 1000;
    protected VERY_LONG_POLL_MS = 5/* minutes */ * 60 * 1000;

    public constructor(gc: GameController) {
        this.log = log.getLogger(this.constructor.name);
        this.log.setLevel(log.levels.DEBUG)
        this.gc = gc;
    }

    public abstract get statusName(): string
    public abstract get statusText(): string
    public get name() : string {
        return this.constructor.name
    }

    // Game setup data handlers - default no-op

    public handleReceiveGame(gameInfo: Game): void {
        // no-op
    }

    public handleReceiveBoard(boardData: string): void {
        // no-op
    }

    public handleReceiveDictionary(wordDictionary: string[]): void {
        // no-op
    }


    handleTileSelected(): void {
        // no-op
    }

    handleTilePlaced(): void {
        // no-op
    }

    /**
     * User has clicked Reset - remove the word from the board
     */
    public handleClickReset() {
        this.log.info(this.constructor.name, '.handleClickReset()')

        this.gc._audio.resetWord();

        this.gc._boardController.resetWord();

        this.gc._lettersModel.resetPlacedCells();
        this.gc._lettersModel.unselect();
        this.gc._lettersView.updateView()

        this.gc.setGameState(this.gc.gameStates.SELECT_FIRST_TILE)
    }

    public playWord(newPlay: Play): void {
        throw new Error(`Invalid State ${this.constructor.name} for playWord`);
    }

    public updateViews(args: {
        buttonsView: ButtonsView,
        lettersView: LettersView
    }): void {
        args.buttonsView.enable('btnMove', false);
        args.buttonsView.enable('btnAttack', false);
        args.buttonsView.enable('btnReset', false);

        args.lettersView.setEnabled(false)
    }

    /**
     * Method called periodically to by controller
     * 
     * @return - The number of milliseconds to wait before this method is called again, 0 = don't call
     */
    public polled(): number {
        return this.VERY_LONG_POLL_MS
    }
}


/**
 * Game board hidden
 */
class GameStateNoGame extends GameState {
    public get statusName(): string {
        return "NO_GAME"
    }

    public get statusText(): string {
        return "__no game__"
    }
}

/**
 * Waiting for another player to join
 */
class GameStateWaitForPlayerToJoin extends GameState {
    public get statusName(): string {
        return "NOT_STARTED"
    }

    public get statusText(): string {
        return "Waiting for other player to join..."
    }

    /**
     * We have have received the game data, load dependant data
     */
    public handleReceiveGame(gameInfo: Game): void {
        this.log.info(this.constructor.name, '.handleReceiveGame(.)');
        this.gc._gameInfo = gameInfo;
        this.gc._gameRuleLogic = new GameRuleLogic(gameInfo.rules, 6);
        this.gc._boardController.cellSideCount = 6; // @TODO: set from board file

        // request board
        if (!this.gc._boardModel.isLoaded) {
            if (!this.gc._gameInfo?.rules?.boardPath) throw new Error("gameInfo.rules.boardPath not set");
            this.gc._remote.loadHtml(this.gc._gameInfo.rules.boardPath)
                .then(boardData => this.gc.gameState.handleReceiveBoard(boardData))
        }

        // request dictionary
        if (!this.gc._gameInfo?.rules?.dictionaryPath) {
            throw new Error("gameInfo.rules.dictionaryPath not set");
        }
        if (this.gc._wordDictionary.length === 0) {
            this.gc._remote.loadJson(this.gc._gameInfo.rules.dictionaryPath)
                .then(dict => {
                    this.gc.gameState.handleReceiveDictionary(dict)
                });
        }
        this.gc._localPlayerIndex = gameInfo.playerList.findIndex(p => p.playerId === this.gc._remote.playerId);
        if (this.gc._localPlayerIndex < 0) {
            throw new Error("Received gamePlayerList which we (playerId=" + this.gc._remote.playerId + ") are not a member");
        }

        this.gc._lettersModel.setLetterCount(gameInfo.rules.letterCount);
        this.gc._lettersView.updateLetters();
        this.gc._lettersView.updateView();

        this.gc.checkGameReady();
    }

    public handleReceiveBoard(boardData: string): void {
        this.log.info(this.constructor.name, '.handleReceiveBoard(.)');
        this.gc._boardController.loadBoard(boardData)
        this.gc.checkGameReady()
    }

    public handleReceiveDictionary(wordDictionary: string[]): void {
        this.gc._wordDictionary = wordDictionary;
        this.gc.checkGameReady();
    }

    public polled(): number {
        this.log.debug(this.constructor.name, '.polled()');
        if (this.gc._gameInfo?.gameId != undefined) {
            this.gc._remote.loadGame(this.gc._gameInfo.gameId)
        }
        return this.SHORT_POLL_MS
    }
}

/**
 * We are executing animations
 */
class GameStateAnimating extends GameState {
    public get statusName(): string {
        return "ANIMATING"
    }

    public get statusText(): string {
        return "Executing moves..."
    }
}

/**
 * We are waiting for the local player to select the first tile
 */
class GameStateSelectFirstTile extends GameState {
    public get statusName(): string {
        return "LOCAL_MOVE"
    }

    public get statusText(): string {
        return "Select your first tile"
    }

    public updateViews(args: {
        buttonsView: ButtonsView,
        lettersView: LettersView
    }): void {
        args.buttonsView.enable('btnMove', false);
        args.buttonsView.enable('btnAttack', false);
        args.buttonsView.enable('btnReset', false);

        args.lettersView.setEnabled(true)
    }

    handleTileSelected(): void {
        this.gc.setGameState(this.gc.gameStates.PLACE_FIRST)
    }

    handleTilePlaced(): void {
        this.gc.setGameState(this.gc.gameStates.ADDITIONAL_TILE)
    }
}

/**
 * Player has selected the first tile, waiting for them to place it
 */
class GameStatePlaceFirstTile extends GameState {
    public get statusName(): string {
        return "LOCAL_MOVE"
    }

    public get statusText(): string {
        return "Place your first tile"
    }


    public updateViews(args: {
        buttonsView: ButtonsView,
        lettersView: LettersView
    }): void {
        args.buttonsView.enable('btnMove', false);
        args.buttonsView.enable('btnAttack', false);
        args.buttonsView.enable('btnReset', true);

        args.lettersView.setEnabled(true)
        args.lettersView.updateView();
    }

    handleTilePlaced(): void {
        this.gc.setGameState(this.gc.gameStates.ADDITIONAL_TILE)
    }
}

/**
 * Player has placed the first tile, waiting for them to place the next tile
 */
class GameStateAdditionalTile extends GameState {
    public get statusName(): string {
        return "LOCAL_MOVE"
    }

    public get statusText(): string {
        return "Select next tile to place"
    }

    public updateViews(args: {
        buttonsView: ButtonsView,
        lettersView: LettersView
    }): void {
        args.buttonsView.enable('btnMove', true);
        args.buttonsView.enable('btnAttack', this.gc._boardController.isAttackInRange());
        args.buttonsView.enable('btnReset', true);

        args.lettersView.setEnabled(true)
    }
}

/**
 * We are waiting for the remote player to make a move
 */
class GameStateRemoteMove extends GameState {
    public get statusName(): string {
        return "REMOTE_MOVE"
    }

    public get statusText(): string {
        return "Waiting for other player's move..."
    }

    public polled(): number {
        this.log.debug(this.constructor.name, '.polled()');
        if (this.gc._gameInfo?.gameId == undefined) throw new Error("_poll() - gameId not set")
        this.gc._remote.loadGame(this.gc._gameInfo.gameId)
        return this.SHORT_POLL_MS
    }
}

/**
 * Local player has won
 */
class GameStateLocalPlayerWin extends GameState {
    public get statusName(): string {
        return "PLAYER_WIN"
    }

    public get statusText(): string {
        return this.gc._getCurrentPlayForPlayer().endGameReason
    }
}

/**
 * Remote player has won
 */
class GameStateRemoteWin extends GameState {
    public get statusName(): string {
        return "REMOTE_WIN"
    }

    public get statusText(): string {
        return this.gc._getCurrentPlayForPlayer().endGameReason
    }
}

