import { log } from '../lib/log';

import { Coordinates } from '../Coordinates';
import { Direction } from '../Direction';


/**
 * Subset of Play object used by AttackRangeStrategy
 */
export type AttackPlay = {
        startCoords: Coordinates;
        endWordCoords: Coordinates | null;
        playedRange: Array<Coordinates>;
        word: { length: number };
        direction: Direction;
};

export class AttackRangeStrategy  {
    readonly cellSideCount: 4 | 6;
    log: log.Logger;

    constructor(cellSideCount: 4 | 6) {
        this.cellSideCount = cellSideCount;
        this.log = log.getLogger(this.constructor.name);
        this.log.setLevel(log.levels.SILENT);
    }

    isAttackInRange(attackerPlay: AttackPlay, defenderCoordinates : Coordinates): boolean {
        if (! attackerPlay.startCoords) throw new Error('attackerPlay.startCoords is null')
        if (! attackerPlay.endWordCoords) throw new Error('attackerPlay.endWordCoords is null')
        if (! attackerPlay.word) throw new Error('attackerPlay.word is null')
        if (! attackerPlay.word.length) throw new Error('attackerPlay.word.length is null')
        if (! attackerPlay.direction) throw new Error('attackerPlay.direction is null')
        if (attackerPlay.direction === Direction.ANY) throw new Error('attackerPlay.direction is ANY')
        if (! defenderCoordinates) throw new Error('defenderCoordinates is null')

        return ! attackerPlay.startCoords.equals(defenderCoordinates)
    }
}
