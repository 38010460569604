import { EventEmitter } from "../lib/EventEmitter";
import { JQuery } from "../lib/jQuery";
import { log } from "../lib/log";

export class View extends EventEmitter {
    protected log : log.Logger = log.getLogger(this.constructor.name);

    protected flash(element: any, flash_class: string): void {
        if (JQuery(element).is(":visible")) {
            JQuery(element).addClass(flash_class);
    
            let animationDuration = this.cssTimeToMilliseconds(JQuery(element).css('animation-duration'));
            setTimeout(() => {
                JQuery(element).removeClass(flash_class);
            }, animationDuration);
        }
    }
    
    protected cssTimeToMilliseconds(cssTime: string): number {
        let match;
        if ((match = cssTime.match(/^([\d\.]+)\s*ms$/))) {
            return Math.floor(parseFloat(match[0]));
        } else if ((match = cssTime.match(/^([\d\.]+)\s*s$/))) {
            return Math.floor(parseFloat(match[0]) * 1000);
        } else {
            throw new Error("cssTimeToMilliseconds() couldn't parse: " + cssTime);
        }
    }
}
