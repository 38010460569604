import { Play } from '../../Play';
import { GamePlayStrategy } from '../GamePlayStrategy';

export class EndGameLowWaterMarkLoserStrategy implements GamePlayStrategy {
    private _lowWaterMark: number;

    constructor(lowWaterMark: number) {
        this._lowWaterMark = lowWaterMark;
    }

    execute(plays: Play[], _args: any): boolean {
        let gameOver = false;

        let lowScore = plays.reduce((prev, play) => {
            if ( null == play.endTurnScore ) throw new Error("EndGameLowWaterMarkLoserStrategy.execute() endTurnScore is null")
            return Math.min(prev, play.endTurnScore)
        }, Number.MAX_SAFE_INTEGER);

        if ( lowScore < this._lowWaterMark ){
            plays.forEach(play => {
                play.lost = ( (play.endTurnScore || 0) < this._lowWaterMark )
                    if (play.lost) {
                        play.endGameReason = "Game over! You have been defeated!";
                    } else {
                        play.endGameReason = "Game over! You beat your opponent";
                    }
            })
        }
        return true
    }
}
