import { $ } from '../lib/jQuery';

import { Play } from '../Play';
import { GameControllerEvent } from './GameController';
import { EventEmitter } from '../lib/EventEmitter';
import { Game } from '../globals';
import { View } from './View';

export class ScoreView extends View {
    private _avatarUi: JQuery<HTMLElement>[];
    private _scoreUi: JQuery<HTMLElement>[];
    private _flagUi: JQuery<HTMLElement>[];
    private _progressUi: JQuery<HTMLElement>[];
    private _attackMultiplierUi: JQuery<HTMLElement>[];

    constructor(playEmitter: EventEmitter) {
        super();
        this.log.setLevel(this.log.levels.INFO);

        this._avatarUi = [
            $('span.avatar.player-0'),
            $('span.avatar.player-1'),
        ];

        this._scoreUi = [
            $('span.score.player-0'),
            $('span.score.player-1'),
        ];

        this._flagUi = [
            $('span.flags.player-0'),
            $('span.flags.player-1'),
        ];

        this._progressUi = [
            $('progress.player-0'),
            $('progress.player-1'),
        ];

        this._attackMultiplierUi = [
            $('span.attackMultiplier.player-0'),
            $('span.attackMultiplier.player-1'),
        ];

        playEmitter.on(GameControllerEvent.GAME_INFO, gameInfo => this._receiveGame(gameInfo))
        playEmitter.on(GameControllerEvent.NEW_TURN, play => this._receivePlay(play, true))
        playEmitter.on(GameControllerEvent.END_TURN, play => this._receivePlay(play, false))
    }

    flashScore(playerIndex: number): void {
        this.flash(this._scoreUi[playerIndex], 'flash-large-pulse');
    }

    private _receiveGame(game: Game): void {
        this._progressUi.forEach((progressUi) => {
            progressUi.attr('max', game.rules.maxGameScore)
        })
        game.playerList.forEach((player, index) => {
            this._avatarUi[index].html(`<img class="avatar" src ="${player.playerPicture}" />`)
        })
    }

    addPlay(play: Play) {
        this._receivePlay(play, false)
    }

    private _receivePlay(play: Play, isNewTurn: boolean): void {
        this.log.info(this.constructor.name, `._receivePlay(., isNewTurn${isNewTurn})`,)

        this._scoreUi[play.playerIndex].toggleClass('lost', play.lost);

        const nextScore = isNewTurn ? play.startTurnScore : play.endTurnScore;
        const prevScore = this._scoreUi[play.playerIndex].text();
        this._scoreUi[play.playerIndex].text(nextScore);
        if (prevScore !== nextScore) {
            this.flashScore(play.playerIndex);
        }
        this._progressUi[play.playerIndex].attr('value', nextScore);

        // show one flag per flag count
        const flagCount = isNewTurn ? play.startFlags.length : play.endFlags.length
        this._flagUi[play.playerIndex].html( 
            `<img class="flag" src="/images/flag-${play.playerIndex}.png" />`.repeat(flagCount)
        );

        const attackMultiplier = isNewTurn ? play.startAttackMultiplier : play.endAttackMultiplier
        this._attackMultiplierUi[play.playerIndex].text('x' + attackMultiplier);
    }
}
