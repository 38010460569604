import { $ } from '../lib/jQuery';

import { GameAnnotated } from '../globals';
import { View } from './View';
import { GameListController } from './GameListController';

export class GameListView extends View {

    private _ui: JQuery;
    private isVisible: boolean  = false;

    constructor() {
        super();
        this.log.setLevel(this.log.levels.INFO)

        this._ui = $('div.game-list');
    }

    setVisibility(isVisible: boolean): void {
        this.isVisible = isVisible;
        this.log.info(this.constructor.name, `.setVisibility( ${isVisible}} )`);

        this._ui.toggle(isVisible);
    }

    getVisibility(): boolean {
        return this.isVisible;
    }

    clearGames(): void {
        this.log.info(this.constructor.name, '.clearGames()');
        this._ui.empty();
    }

    appendJoinGameControl( buttonTitle: string = 'Join Game' ): void {
        this.log.info(this.constructor.name, '.appendJoinGameControl()');

        $("<div>", { class: "gamelist-control" }).append(
            $('<span>', { class: "gamelist-control" }).text(`<< ${buttonTitle} >>`)
        )
            .off('click')
            .on('click', (() => this.emit(GameListController.JOIN_GAME)))
            .appendTo(this._ui);
    }

    appendNewGameControl( buttonTitle: string = 'New Game' ): void {
        this.log.info(this.constructor.name, '.appendNewGameControl()');

        $("<div>", { class: "gamelist-control" }).append(
            $('<span>', { class: "gamelist-control" }).text(`<< ${buttonTitle} >>`)
        )
            .off('click')
            .on('click', (() => this.emit(GameListController.PROMPT_NEW_GAME)))
            .appendTo(this._ui);
    }

    /**
     * Appends game information.
     * @param game - The information about the game to append.
     */
    appendGame(game: GameAnnotated): void {
        this.log.info(this.constructor.name, '.appendGame()');

        $("<div>", { class: "game-info", id: game.gameId }).append(
            $("<img>", { class: "avatar", src: game.opponentPlayer?.playerPicture }),
            $("<span>", { class: "opponent-name" }).text(
                game.opponentPlayer?.playerName ?? "Opponent"
            ),
            $("<span>", { class: "turn-info" }).append(
                $("<span>", { class: "turn-number" }).text(
                    game.turnNumber > 0 ? `Turn: ${game.turnNumber}` : ''
                ),
                $("<span>", { class: "whose-turn" }).text(
                    game.whoseTurnText
                )
            )
        )
            .off('click')
            .on('click', () => {
                this.emit(GameListController.GAME_SELECTED, game.gameId);
            })
            .appendTo(this._ui);
    }
}
