export class PlayType {
    static readonly ATTACK = new PlayType('ATTACK');
    static readonly MOVE = new PlayType('MOVE');

    private constructor(private value: string) {}

    // http://stackoverflow.com/questions/4878756/javascript-how-to-capitalize-first-letter-of-each-word-like-a-2-word-city
    private toTitleCase(instr : string) {
        return instr.replace(/\w\S*/g, str => str.charAt(0).toUpperCase() + str.substring(1).toLowerCase());
    }

    toString() : string {
        return this.toTitleCase(this.value);
    }

    toJSON() {
        return this.value;
    }
    
    static toPlayType(value: any) : PlayType | null {
        if ( !value ) {
            return null;
        } else if (value instanceof PlayType) {
            return value;
        } else if (typeof value === 'string') {
            value = value.trim().toUpperCase();
            if (value === 'ATTACK') {
                return PlayType.ATTACK;
            } else if (value === 'MOVE') {
                return PlayType.MOVE;
            }
        }
    
        throw new Error('Invalid PlayType: ' + value);
    }
}
