import { Play } from '../../Play';
import { PlayType } from '../../PlayType';
import { GamePlayStrategy } from '../GamePlayStrategy';

// updates the attackMultiplier based on playType and increment settings
export class IncrementAttackMultiplierStrategy implements GamePlayStrategy {
    private _incrementAfterMove: number;
    private _incrementAfterAttack: number;

    constructor(incrementAfterMove: number, incrementAfterAttack: number) {
        this._incrementAfterMove = incrementAfterMove;
        this._incrementAfterAttack = incrementAfterAttack;
    }

    execute(plays: Play[], _args: any): boolean {
        plays.forEach(play => {
            if (null == play.startAttackMultiplier) throw new Error("IncrementAttackMultiplierStrategy.execute() startAttackMultiplier is null")
            switch (play.playType) {
                case PlayType.ATTACK:
                    play.endAttackMultiplier = play.startAttackMultiplier + this._incrementAfterAttack;
                    break;
                case PlayType.MOVE:
                    play.endAttackMultiplier = play.startAttackMultiplier + this._incrementAfterMove;
                    break;
                default:
                    throw new Error("IncrementAttackMultiplierStrategy.execute() playType is invalid: " + play.playType);
            }
        })
        return true;
    }
}
