import { log } from '../../lib/log';
import { Play } from '../../Play';
import { GamePlayStrategy } from '../GamePlayStrategy';

export class KnockBackPlayStrategy implements GamePlayStrategy {
    private log = log.getLogger(this.constructor.name);

    constructor() {
        this.log.setLevel(log.levels.SILENT)
    }

    _arePlayersOnSameCell = (plays: Play[]): boolean => {
        if (!plays[0].endCoords || !plays[1].endCoords) throw new Error('play[].endCoords is undefined');

        return (plays[0].endCoords.equals(plays[1].endCoords)) || false;
    }

    private _knockBackPlayer = (play: Play): void => {
        this.log.info(this.constructor.name, '.knockBackPlayer(.)');
        if (!play.endCoords) throw new Error('play.endCoords is undefined');

        play.endCoords = play.direction.opposite.nextCoordinates(play.endCoords)

    }

    execute(plays: Play[], _args: any): boolean {
        if (this._arePlayersOnSameCell(plays)) {
            switch (plays[0].cmp(plays[1])) {
                case -1:
                    this._knockBackPlayer(plays[0]);
                    break;
                case 0:
                    this._knockBackPlayer(plays[0]);
                    this._knockBackPlayer(plays[1]);
                    break;
                case 1:
                    this._knockBackPlayer(plays[1]);
                    break;
            }
        }
        return true;
    }
}
