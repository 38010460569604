import { log } from '../lib/log';

import { BoardView } from './BoardView';

export class BoardTesterView extends BoardView {

	constructor( cellSideCount: 4 | 6, baseBoardView: BoardView ) {
		super(baseBoardView._boardModel, baseBoardView._table, baseBoardView._status, baseBoardView._body, baseBoardView._wordLists);
		this.log.setLevel(log.levels.SILENT);
        
		this.cellSideCount = cellSideCount;
        this.renderTestBoard()
	}

	/**
	 * Debug function - render a test board.
	 */
	renderTestBoard() {
		let boardRow = '<!!!.234.@#$.Z';
		this._boardModel.loadBoard((boardRow + '\n').repeat(8));
		this.loadBoard();

		new ___rowUpdate(this._table, 'tr:eq(0)', '(default= +unplayed)')
		new ___rowUpdate(this._table, 'tr:eq(1)').removeClass('unplayed').addClass('played')
		new ___rowUpdate(this._table, 'tr:eq(2)').addClass('player-0').removeClass('unplayed').addClass('played')
		new ___rowUpdate(this._table, 'tr:eq(3)').addClass('player-1').removeClass('unplayed').addClass('played')
		new ___rowUpdate(this._table, 'tr:eq(4)').addClass('placeable')
		new ___rowUpdate(this._table, 'tr:eq(5)').addClass('placeable').removeClass('unplayed').addClass('played')
		new ___rowUpdate(this._table, 'tr:eq(6)').addClass('attackable')
		new ___rowUpdate(this._table, 'tr:eq(7)').addClass('attackable').removeClass('unplayed').addClass('played')

		this._table.append('<tr class="desc"' + 'style="height:300px;">' +
			boardRow.split('').map(char => {
				let desc = char;
				switch (char) {
					case '<': desc = 'player-0'; break;
					case '.': desc = '(empty)'; break;
					case '!': desc = 'flag'; break;
					case '2': desc = char + 'x bonus letter'; break;
					case '3': desc = char + 'x bonus letter'; break;
					case '4': desc = char + 'x bonus letter'; break;
					case '@': desc = '2x bonus word'; break;
					case '#': desc = '3x bonus word'; break;
					case '$': desc = '4x bonus word'; break;
					case 'Z': desc = 'static'; break;
				}
				return '<td style="writing-mode: vertical-lr; white-space: nowrap; background-color: #FAF9F6">' +
					'<div>' +
					desc +
					'</div>' +
					'</td>'
			})

			+ '</tr>')

		// column settings that can't be done via loadBoard
		new ___colUpdate(this._table, 'td:nth-child(3)').addClass('claimed-0').addClass('played').removeClass('unplayed');
		new ___colUpdate(this._table, 'td:nth-child(4)').addClass('claimed-1').addClass('played').removeClass('unplayed');
	}
}

class ___rowUpdate {
	element: JQuery<Element>;
	row: JQuery<Element>;

	constructor(table: JQuery<Element>, trSelector: string, description: string = '') {
		this.row = table.find(trSelector);
		this.element = this.row.find('td')
		this.row.append(`<td class='desc' style="text-align: left; white-space: nowrap; background-color: #FAF9F6">${description}</td>`);

	}
	_desc(desc: string) {
		let td = this.row.find('td.desc')
		td.text(td.text() + (td.text().length > 0 ? ' ' : '') + desc)
	}
	addClass(className: string) { this._desc('+' + className); this.element.addClass(className); return this };
	removeClass(className: string) { this._desc('-' + className); this.element.removeClass(className); return this };
}

class ___colUpdate {
	element: JQuery<Element>;
	descRow: JQuery<Element>;
	tdSelector: string;

	constructor(table: JQuery<Element>, tdSelector: string, description: string = '') {
		this.tdSelector = tdSelector
		this.descRow = table.find("tr.desc");
		this.element = table.find('tr ' + tdSelector)
	}
	_desc(desc: string) {
		let td = this.descRow.find(this.tdSelector + ' div')
		td.text(td.text() + (td.text().length > 0 ? ' ' : '') + desc)
	}
	addClass(className: string) { this._desc('+' + className); this.element.addClass(className); return this };
	removeClass(className: string) { this._desc('-' + className); this.element.removeClass(className); return this };
}
