import { Play } from '../../Play';
import { GamePlayStrategy } from '../GamePlayStrategy';

export class SetEndTurnScoreStrategy implements GamePlayStrategy {
    execute(plays: Play[], _args: any): boolean {
        plays.forEach(play => {
            if (null == play.startTurnScore) throw new Error("SetEndTurnScoreStrategy.execute() startTurnScore is null")
            if (null == play.turnPoints) throw new Error("SetEndTurnScoreStrategy.execute() turnPoints is null")

            play.endTurnScore = play.startTurnScore + play.turnPoints;
        })
        return true; // return true if the function executes successfully
    }
}
