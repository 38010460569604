import { Coordinates } from './Coordinates';

export class PlayEvent {
	coords: Coordinates | undefined;
	letter: string | undefined;
	scoreIncrement: number;
	cellScoreMultiplier: number;
	minScore: number | undefined;
	maxScore: number | undefined;
	wordScoreMultiplier: number;
	wordIncrement: number;
	attackMultiplier: number;

	constructor({
		coords, letter, scoreIncrement = 0, cellScoreMultiplier = 1, minScore, maxScore, wordScoreMultiplier = 1, wordIncrement = 0, attackMultiplier = 1,
	}: {
		coords?: Coordinates;
		letter?: string;
		scoreIncrement?: number;
		cellScoreMultiplier?: number;
		minScore?: number;
		maxScore?: number;
		wordIncrement?: number;
		wordScoreMultiplier?: number;
		attackMultiplier?: number;
	} = {}) {
		this.coords = coords;
		this.letter = letter;
		this.scoreIncrement = scoreIncrement;
		this.cellScoreMultiplier = cellScoreMultiplier;
		this.minScore = minScore ?? undefined;
		this.maxScore = maxScore ?? undefined;
		this.wordScoreMultiplier = wordScoreMultiplier;
		this.wordIncrement = wordIncrement;
		this.attackMultiplier = attackMultiplier;
	}

	static MULIPLY = '×'

	get description() : string {
		if (this.letter) return `+${this.letter}{${this.scoreIncrement}}`
		if (this.cellScoreMultiplier > 1) return `${PlayEvent.MULIPLY}${this.cellScoreMultiplier} {letter-bonus}`
		
		if (this.wordIncrement) return `+${this.wordIncrement} {word-length bonus}`
		if (this.wordScoreMultiplier > 1) return `${PlayEvent.MULIPLY}${this.wordScoreMultiplier} {word-bonus}`
		if (this.attackMultiplier > 1) return `${PlayEvent.MULIPLY}${this.attackMultiplier} {attack}`

		if (this.minScore !== undefined) return `| { min = ${this.minScore} }`
		if (this.maxScore !== undefined) return `| { max = ${this.maxScore} }`

		return ''
	}
}

