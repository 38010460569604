import { AttackPlay, AttackRangeStrategy } from './AttackRangeStrategy';
import { Coordinates } from '../Coordinates';


// attack is in-range if the end of the word is within 'attackRange' cells of the opponent
export class RadiusAttackRangeStrategy extends AttackRangeStrategy {
	readonly attackRange: number;

	constructor(cellSideCount: 6 | 4, attackRange: number) {
		super(cellSideCount);
		this.attackRange = attackRange;
	}

    isAttackInRange(attackerPlay: AttackPlay, defenderCoordinates : Coordinates): boolean {
		this.log.info(this.constructor.name, '.isAttackInRange(..)')
		if ( ! super.isAttackInRange(attackerPlay, defenderCoordinates) ) return false;

		if ( attackerPlay.endWordCoords == null ) throw new Error('attackerPlay.endWordCoords is null')

		return (defenderCoordinates.distanceTo( attackerPlay.endWordCoords, this.cellSideCount ) <= this.attackRange);
	}
}
