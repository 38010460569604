import { $, JQuery } from '../lib/jQuery';

import { CellModel } from './CellModel';
import { CellView, CELL_CLICKED } from './CellView'
import { Play } from '../Play'
import { Direction } from '../Direction';
import { View } from './View';

export class BoardView extends View {
	_boardModel: any;
	_table: JQuery<HTMLElement>;
	_status: JQuery<HTMLElement>;
	_body: JQuery<HTMLElement>;
	_wordLists: JQuery<HTMLElement>[];
	private _cellSideCount: 4 | 6 = 6;

	constructor(
		boardModel: any,
		table: JQuery<HTMLElement> = $('table.gameboard'),
		status: JQuery<HTMLElement> = $('div.status'),
		body: JQuery<HTMLElement> = $('body'),
		wordLists: JQuery<HTMLElement>[] = [
			$('div.playedwords.player-0 ul'),
			$('div.playedwords.player-1 ul'),
		]
	) {
		super();
		this.log.setLevel(this.log.levels.SILENT);

		this._boardModel = boardModel
		this._table = table
		this._status = status
		this._body = body
		this._wordLists = wordLists
	}

	loadBoard() {
		let htmlData = CellView.getCellViewTableBody(this._boardModel.boardCells)
		this._table.html(htmlData[0])

		CellView.onAllCells(this._boardModel.boardCells,
			CELL_CLICKED, (cellView: CellView) => {
				this.emit(CELL_CLICKED, cellView)
			});

	}

	get cellSideCount() : 4 | 6 {
		return this._cellSideCount
	}

	set cellSideCount(cellSideCount: 4 | 6) {
		this._cellSideCount = cellSideCount
		this._table.removeClass('sides-4 sides-6')
		this._table.addClass(`sides-${cellSideCount}`)
	}

	setStatus(statusMessage: string) {
		this._status.text(statusMessage);
	}

	setStatusMood(gameStatusName: string) {
		// clear existing mood
		this._body.removeClass((index, className) => {
			return (className.match(/\bstate_\S+/g) || []).join(' ');
		})
		this._body.addClass(`state_${gameStatusName}`)
	}

	clearPlayedItems() {
		this.log.info(this.constructor.name, '.clearPlayedItems()');
		this._wordLists.forEach(
			wordList => wordList.empty()
		);
	}

	addPlay(play: Play) {
		this.log.info(this.constructor.name, '.addPlay(play=.)');
		while ( this._wordLists[play.playerIndex].children().length < play.turnIndex + 1 ) {
			this._wordLists[play.playerIndex].append($('<li/>'))
		}
		this._wordLists[play.playerIndex].children('li').eq(play.turnIndex)
			.text(play.toPlayDescription())
			.prop('title', play.turnPointDescription) // hover, mouse-over tooltip text
			.get(0)?.scrollIntoView(false);
	}

	flashPlaceableRow(fromCell: CellModel, direction: Direction) {
		fromCell.getAllCellsInDirection(direction).forEach((cell, index) => {
			setTimeout(() => {
				this.flash(cell.cellView, 'flash-play-direction');
			}, index * 10); // delay creates cascading strobe effect
		});
	}

	// flash entire board
	flashBoard() {
		this.flash(this._table, 'flash-error');
	}

	flashPlayer(playerIndex: number) {
		var cell = this._boardModel.getPlayerCell(playerIndex).cellView;
		this.flash(cell, 'flash-large-pulse');
	}

	flashAttackOnPlayer(playerIndex: number) {
		this._boardModel.getPlayerCell(playerIndex).cellView.flashAttack();
	}
}
