import { Play } from '../../Play';
import { PlayType } from '../../PlayType';
import { GamePlayStrategy } from '../GamePlayStrategy';

// execute(plays) returns whether the plays match the given specified playType combination 
// new PlayCombination( [ Play.ATTACK, Play.MOVE ] )
export class PlayTypeCombinationConditionalStrategy implements GamePlayStrategy {
    private _playCombination: string;

    constructor(playTypeList: PlayType[]) {
        if (playTypeList.length !== 2) throw new Error("PlayTypeCombinationConditionalStrategy constructor requires exactly 2 PlayType arguments");

        this._playCombination = playTypeList.join('.');
    }

    execute(plays: Play[], _args: any): boolean {
        return (
            plays[0].playType + '.' + plays[1].playType === this._playCombination ||
            plays[1].playType + '.' + plays[0].playType === this._playCombination
        )
    }
}
