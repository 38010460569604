import { $, JQuery } from '../lib/jQuery';

import { LettersModel } from './LettersModel';
import { View } from './View';


export class LettersView extends View {
    private _isEnabled: boolean = false

    private _lettersModel: LettersModel;

    private _uiTilesTable: any;
    private _uiTiles: Array<any>;
    private _letters: string = '';

    static TILE_SELECTED : string = 'TILE_SELECTED';
    
    constructor(
        lettersModel: any,
        lettersTable: any = $('table.letters')
    ) {
        super();
        this.log.setLevel(this.log.levels.DEBUG)

        this._lettersModel = lettersModel
        this._uiTilesTable = lettersTable
        this._uiTiles = this._uiTilesTable.find('td')
    }

    setEnabled(isEnabled: boolean): void {
        this.log.info('LettersView.setEnabled(', isEnabled, ')');
        this._isEnabled = isEnabled;
    }

    updateView(): void {
        this.log.info(this.constructor.name, '.updateView()');
        this.updateLetters();
        this.updateSelection();
        this.updatePlaced();
    }

    updateLetters(): void {
        this.log.info(this.constructor.name, '.updateLetters()');

        let letters = this._lettersModel.getLetters()
        this.log.debug(this.constructor.name, '.updateLetters() - ', letters);

        // recreate the tile row if the number of letters has changed
        if (letters.length != this._uiTiles.length) {
            this._uiTilesTable.find('tbody tr').remove()
            this._uiTiles = []

            let tr = JQuery('<tr>')
            this._uiTilesTable.find('tbody').append(tr)
            for (let index = 0; index < this._lettersModel.getLetterCount(); ++index) {
                let letter = letters[index];
                let td = JQuery('<td>')
                    .append($('<span>').addClass('letter')).append($('<span>').addClass('score'))
                    .off('click')
                        .on('click', () => {
                            this.handleClick(index);
                        });
                tr.append(td)
                this._uiTiles.push(td)
            }
        }

        // if the letters have changed, update and animate the tiles
        if ( this._letters !== letters ) {
            this._uiTiles.forEach((td : any, index : number) => {
                let letter = letters[index];
                let score = this._lettersModel.getScore(index);
                $(td).find('span.letter').text(letter);
                $(td).find('span.score').text(score);
                setTimeout(() => {
                    if (letter !== '' && letter !== ' ') {
                        this.flash(td, 'flash-populate-tile');
                    }
                }, index * 20);
            });
            this._letters = letters;
        }
    }

    updateSelection(): void {
        this.log.debug(this.constructor.name, '.updateSelection()');
        this._uiTilesTable.find('td.selected').removeClass('selected');
        let index = this._lettersModel.getSelectedIndex();
        this._uiTilesTable.find(`td:eq(${index})`).addClass('selected');
    }

    updatePlaced(): void {
        this.log.debug(this.constructor.name, '.updatePlaced()');
        this._uiTiles.forEach((value : any, index : number) => {
            $(value).toggleClass('placed', this._lettersModel.isPlaced(index));
        });
    }

    private handleClick(index: number): void {
        this.log.debug(this.constructor.name, '.handleClick(', index, ')')
        if (this._isEnabled) {
            let letter = this._letters[index];
            this.emit(LettersView.TILE_SELECTED, {index, letter});
        }
    }

    /**
     * Flash the letters table - UI nudge to user when they click in the wrong place
     * @param flash_class 
     */
    flashView(): void {
        this.flash(this._uiTilesTable, 'flash-selection');
    }
}
