import { log } from '../lib/log';
import { $, JQuery } from '../lib/jQuery';

import { BoardView } from './BoardView';

export class BoardEditorView extends BoardView {
    protected _editDiv: JQuery<Element>;
    protected _editStatus: JQuery<Element>;
    protected _textarea: JQuery<Element>;
    protected _hMirror: JQuery<Element>;
    protected _vMirror: JQuery<Element>;
    protected _rows: JQuery<Element>;
    protected _cols: JQuery<Element>;
    private _prevBoardText: string = '';

    constructor(
        cellSideCount: 4 | 6,
        baseBoardView: BoardView,
        editDiv: JQuery<Element> = $('div.board-editor'),
    ) {
        super(baseBoardView._boardModel, baseBoardView._table, baseBoardView._status, baseBoardView._body, baseBoardView._wordLists);
        this.log.setLevel(log.levels.DEBUG);

        this.cellSideCount = cellSideCount;

        this._editDiv = editDiv;

        this._editStatus = this._editDiv.find('div.edit-status');
        this._hMirror = this._editDiv.find('div #vertical-mirror');
        this._vMirror = this._editDiv.find('div #vertical-mirror');

        this._rows = this._editDiv.find('div #rows');
        this._cols = this._editDiv.find('div #cols');

        this._textarea = this._editDiv.find('textarea');

    }

    loadBoardTemplate(boardText: string) {
        this.log.info('.loadBoardTemplate()');
        this._textarea.val(boardText);

        this.setVisible();

        this._rows.val(boardText.split('\n').length);
        this._cols.val(boardText.split('\n').reduce((max, line) => Math.max(max, line.replace(/ /g, '').length), 0));
        this.renderBoard();

        this._rows
            .off('input')
            .on('input', () => { this.handleRowColChange() });
        this._cols
            .off('input')
            .on('input', () => { this.handleRowColChange() });
        this._textarea
            .off('input')
            .on('input', () => { this.handleTextAreaUpdate() });
    }

    setVisible() {
        this._editDiv.css('display', 'block');
        // inelegant way to hide the other controls
        $('tr.status').css('display', 'none');
        $('tr.buttons').css('display', 'none');
        $('tr.score').css('display', 'none');
    }

    handleRowColChange() {
        this.log.info('.handleRowColChange()');
        this.resizeBoard();
        this.renderBoard();
    }

    resizeBoard() {
        let rows = parseInt(this._rows.val() as string);
        let cols = parseInt(this._cols.val() as string);
        let boardText = this.normalisedBoard();
        let lines = boardText.split('\n');
        let newLines: string[] = [];
        for (let i = 0; i < rows; i++) {
            newLines.push(((lines[i] ?? '') + '.'.repeat(cols)).slice(0, cols));
        }
        this._textarea.val(newLines.join('\n'));
    }

    handleTextAreaUpdate() {
        this.log.info('.handleTextAreaUpdate()');
        let updated = false;
        if (this._rows.val() !== this.lineCount().toString()) {
            this._rows.val(this.lineCount());
            updated = true;
        }
        if (this._cols.val() !== this.maxColCount().toString()) {
            this._cols.val(this.maxColCount());
            updated = true;
        }
        if (!updated) {
            this.renderBoard();
        }
    }

    renderBoard() {
        this.log.info('.renderBoard()');
        try {
            let boardText = this.normalisedBoard();

            this._textarea.val(this.denormaliseBoard(boardText));
            if (boardText !== this._prevBoardText) {
                this._prevBoardText = boardText;
                this._boardModel.loadBoard(boardText);
                this._textarea.css('background-color', 'white');
                this._editStatus.text('');
            }

        } catch (e) {
            this._textarea.css('background-color', 'red');
            this._editStatus.text(e.message);
            console.error(e);
        }
        this.loadBoard();

    }

    normalisedBoard() : string {
        this.log.info('.normalisedBoard(...)');
        this.log.debug(`.normalisedBoard(...) cellSideCount = ${this.cellSideCount}`);
        let boardText = this._textarea.val() as string;
        let lines = boardText.split('\n').map((line) => line.replace(/ /g, ''));
        return lines.join('\n');
    }

    denormaliseBoard(boardText: string) : string {
        if ( this.cellSideCount !== 6 ) return boardText;

        let newLines : string[] = [];
        let lines = boardText.split('\n');
        lines.forEach((line, index) => {
            newLines[index] = ( index % 2 === 0 ? ' ' : '') + 
                line.split('').join(' ');
        });

        return newLines.join('\n');
    }

    protected lineCount(): number {
        return this.normalisedBoard().split('\n').length;
    }

    protected colCount(): number {
        return this.normalisedBoard().split('\n')[0].length;
    }

    protected maxColCount(): number {
        return this.normalisedBoard().split('\n').reduce((max, line) => Math.max(max, line.length), 0);
    }

}
