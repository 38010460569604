import { Play } from '../../Play'
import { BoardModel } from '../../ui/BoardModel';
import { GamePlayStrategy } from '../GamePlayStrategy'

export class RemovePlayedBonusCellsStrategy implements GamePlayStrategy {
    execute(plays: Play[], args: { boardModel : BoardModel } ): boolean {
        plays.forEach(play => {
            if (!play.startCoords) throw new Error("RemovePlayedBonusCellsStrategy.execute() startCoords is null")
            if (!play.endWordCoords) throw new Error("RemovePlayedBonusCellsStrategy.execute() endWordCoords is null")

            args.boardModel.getCellsForCoordinates(play.playedRange).forEach( cell => {
                cell.resetBonus()
                cell.playCell(play.playerIndex)
            })
        })
        return true
    }
}
