import { Play } from '../../Play';
import { GamePlayStrategy } from '../GamePlayStrategy';

// the winning play and the losing play; parameter to for the Scoring function
export interface Outcome {
    winner: Play;
    loser: Play;
}

// Outcome plus the winning plays and the losing plays as arrays (for future >2 player games); return type for getPlaysByOutcome
export interface ExtendedOutcome extends Outcome {
    _winners: Play[];
    _losers: Play[];
}

// A function that takes { winner: Play, loser: Play } returns the turnPoints for the winner/loser
type ScoringFunction = (outcome: Outcome) => number;


export abstract class OutcomeMetaStrategy implements GamePlayStrategy {
    private _getWinnerPointFunction: (outcome: ExtendedOutcome) => number;
    private _getLoserPointFunction: (outcome: ExtendedOutcome) => number;

    /**
     * An abstract metastrategy that determines the winner and loser of a turn
     * The winner and loser functions of the constructor set the turnPoints for the winner and loser, respectively
     *
     * Subclasses must implement getPlaysByOutcome which will determines the winner and loser logic
     * 
     * @param winner A function that takes { winner: Play, loser: Play } returns the turnPoints for the winner
     * @param loser  A function that takes { winner: Play, loser: Play } returns the turnPoints for the loser
     */
    constructor({ winner, loser }: { winner: ScoringFunction, loser:  ScoringFunction }) {
        this._getWinnerPointFunction = winner;
        this._getLoserPointFunction = loser;
    }

    /**
     * Examine the plays and determine the winner and loser
     * @param plays The Plays for this turn to evaluate
     * @returns An object with the winning and losing plays, as well as the arrays of winning and losing plays
     */
    protected abstract getPlaysByOutcome(plays: Play[]): ExtendedOutcome;

    /**
     * Execute the strategy using logic from getPlaysByOutcome
     * @param plays The plays to evaluate and assign final turnPoints to
     * @returns true
     */
    execute(plays: Play[], _args: any): boolean {
        let playsByOutcome = this.getPlaysByOutcome(plays);

        // we need to store these for final assignment as intermediate steps may use the original values
        let playerIndexToFinalTurnPoints : { [key: number]: number } = {};

        playsByOutcome._winners.forEach(play => {
            playerIndexToFinalTurnPoints[play.playerIndex] = this._getWinnerPointFunction(playsByOutcome);
        });
        playsByOutcome._losers.forEach(play => {
            playerIndexToFinalTurnPoints[play.playerIndex] = this._getLoserPointFunction(playsByOutcome);
        });

        plays.forEach(play => {
            play.turnPoints = playerIndexToFinalTurnPoints[play.playerIndex];
        });
        return true;
    }

}
