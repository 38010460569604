import { Coordinates } from '../../Coordinates';
import { log } from '../../lib/log';

import { Play } from '../../Play';
import { BoardModel } from '../../ui/BoardModel';
import { GamePlayStrategy } from '../GamePlayStrategy';

/**
 * Cells containing a flag can be claimed by exactly one player
 * The most recent play will claim the flag
 * In case of simultaneous claim, the stronger Play will claim
 */
export class ClaimFlagCellStrategy implements GamePlayStrategy {
    log = log.getLogger("ClaimFlagCellStrategy")

    execute(plays: Play[], args: { boardModel : BoardModel } ): boolean {
        log.setLevel(log.levels.ERROR)
        log.info(`${this.constructor.name} execute()`)

        // sorting the plays means the stronger play will overwrite the weaker play in case of clash
        let sortedPlays = [...plays].sort((a, b) => a.cmp(b));

        let claimedFlags = new Map();
        sortedPlays.forEach(play => {
            play.startFlags.forEach((flag) => {
                log.debug(`${this.constructor.name} Starting flag at ${flag} by ${play.playerIndex}`)
                claimedFlags.set(flag.toString(), play);
            })
        })

        sortedPlays.forEach(play => {
            if (!play.startCoords) throw new Error("ClaimFlagCellStrategy.execute() startCoords is null")
            if (!play.endWordCoords) throw new Error("ClaimFlagCellStrategy.execute() endWordCoords is null")

            args.boardModel.getCellsForCoordinates(play.playedRange).forEach((cell) => {
                if (cell.hasFlag) {
                    cell.resetFlagClaim()
                    cell.claimFlag(play.playerIndex)
                    cell.playCell(play.playerIndex)

                    claimedFlags.set(cell.coordinates.toString(), play);
                }
            })
        })


        // iterate over the claimed flags
        claimedFlags.forEach((play, coordinates) => {
            log.debug(`${this.constructor.name} End flag at ${coordinates} by ${play.playerIndex}`)
            play.endFlags.push(Coordinates.toCoordinates(coordinates));
        })
        return true
    }
}
