import { log } from '../../lib/log';

import { Play } from '../../Play';
import { PlayType } from '../../PlayType';
import { GamePlayStrategy } from '../GamePlayStrategy';

export class UpdatePositionStrategy implements GamePlayStrategy {
    private log = log.getLogger(this.constructor.name);

    constructor() {
        this.log.setLevel(log.levels.INFO)
    }

    execute(plays: Play[], _args: any ) : boolean {
        plays.forEach(play => {
            if (null == play.startCoords) throw new Error("UpdatePositionStrategy.execute() startCoords is null")
            if (null == play.endWordCoords) throw new Error("UpdatePositionStrategy.execute() endWordCoords is null")

            switch (play.playType) {
                case PlayType.MOVE:
                    play.endCoords = play.endWordCoords;
                    break;
                case PlayType.ATTACK:
                    play.endCoords = play.startCoords;
                    break;
                default:
                    throw new Error("UpdatePositionStrategy.execute() playType is invalid: " + play.playType);
            }

        })
        return true
    }
}
