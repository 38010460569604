import { $ } from '../lib/jQuery';
import { View } from './View';

export class BackButtonView extends View {
    private _ui: JQuery;

    constructor() {
        super();
        this._ui = $('.back-button');
        this._ui.off('click').on('click', () => {
            this.emit('CLICK');
        });
    }
}
