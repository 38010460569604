

import { log } from '../lib/log'
import { EventEmitter } from '../lib/EventEmitter'
import { createAuth0Client, Auth0Client } from '@auth0/auth0-spa-js'
import { Player } from '../globals'


// Happy path:
// 1) No login, no #hash data   -> this.showLogin
//    Redirect to Auth0
// 2) Callback with #hash data  -> this.saveLogin
//    2a) this.authenticated
//        -> Emit AuthController.AUTHENTICATED

// Alternative: Manual logout:
// 1) hash #logout -> this.showLogin
// (then as above)

class AuthController extends EventEmitter {
  static AUTHENTICATED = 'AUTHENTICATED'
  static DEFAULT_PICTURE_URL = "/images/avatar-unknown.png"

  private auth0Client: Auth0Client | null = null;

  private _userId: string | null = null;
  private _userName: string | null = null;
  private _idToken: string | null = null;
  private _userPicture: string | null = null;

  public get userId(): string | null {
    return this._userId;
  }

  public get userName(): string | null {
    return this._userName;
  }

  public get userPicture(): string | null {
    return this._userPicture;
  }

  public get idToken(): string | null {
    return this._idToken;
  }

  public get playerInfo(): Player {
    return {
      playerId: this._userId ?? '??',
      playerName: this._userName || 'Player',
      playerPicture: this._userPicture || AuthController.DEFAULT_PICTURE_URL,
      isEnabled: true,
      createGameAllowed: true,

      gameIdList: [],
    }
  }

  private log: log.Logger;
  private user: any;

  /**
   * Private constuctor; use static getAuthController() to create an instance.
   */
  private constructor() {
    super();
    this.log = log.getLogger(this.constructor.name);
    this.log.setLevel(log.levels.DEBUG)
  }

  static async getAuthController(authConfig: any) : Promise<AuthController> {
    if ( ! authConfig.clientId || authConfig.clientId === "undefined"
    || ! authConfig.domain || authConfig.domain === "undefined" ) {
      alert("AuthController.getAuthController: Missing clientId or domain in authConfig");
      console.error("AuthController.getAuthController: authConfig: ", JSON.stringify(authConfig, null, 2));
      throw Error("AuthController.getAuthController: Missing clientId or domain in authConfig");
    }

    let authController = new AuthController()
    authController.auth0Client = await createAuth0Client(authConfig)
    return authController
  }


  /**
   * Logs in the user and redirects to the specified target URL.
   * @param targetUrl - The URL to redirect to after successful login.
   * @returns A promise that resolves when the login process is complete.
   */
  async login(targetUrl?: string): Promise<void> {
    this.log.info(this.constructor.name, 'login( targetUrl: ', targetUrl, ')');

    try {
      let options: any = {
        authorizationParams: {
          redirect_uri: window.location.origin
        }
      };

      if (targetUrl) {
        options.appState = { targetUrl };
      }

      await this.auth0Client?.loginWithRedirect(options);
    } catch (err) {
      console.error("Log in failed", err);
    }
  }

  async logout() {
    try {
      this.log.info(`${this.constructor.name}.logout()`);
      await this.auth0Client?.logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      });
    } catch (err) {
      console.error("Log out failed", err);
    }
  };

  async requireAuth(fn: Function, targetUrl: string) {
    const isAuthenticated = await this.auth0Client?.isAuthenticated();

    if (isAuthenticated) {
      return fn();
    }

    return this.login(targetUrl);
  };

  async onWindowLoad(windowHash: string) {
    this.log.info(this.constructor.name, '.onWindowLoad(', windowHash, ')');

    if (windowHash === '#logout') {
      this.log.debug(`  #logout`);
      this.auth0Client?.logout();
      this.login();
      return;
    }

    const isAuthenticated = await this.auth0Client?.isAuthenticated();

    if (isAuthenticated) {
      this.log.info("  User is authenticated");
      window.history.replaceState({}, document.title, window.location.pathname);

      this._emitAuthenticated();
      return;
    }

    this.log.info(` User not authenticated`);

    const query = window.location.search;
    const shouldParseResult = query.includes("code=") && query.includes("state=");

    if (shouldParseResult) {
      this.log.info(` Parsing redirect`);
      try {
        await this.auth0Client?.handleRedirectCallback();
      } catch (err) {
        console.error("Error parsing redirect:", err);
      }
      this._emitAuthenticated()

      window.history.replaceState({}, document.title, "/");
    } else {
      this.login();
    }
  };

  async _emitAuthenticated() {

    if (!this.auth0Client) throw Error("_emitAuthenticated: this.auth0Client null");
    const user = await this.auth0Client?.getUser();
    if (!user) throw Error("_emitAuthenticated: User not found");

    this._userId = user.sub || null;
    this._userName = this._nicknamePlayerItem(user);
    this._idToken = (await this.auth0Client?.getTokenSilently() || null);
    this._userPicture = user.picture || AuthController.DEFAULT_PICTURE_URL;

    if (!this._userId) throw Error("_emitAuthenticated: this._userId null");
    if (!this._userName) throw Error("_emitAuthenticated: this._userName null");
    if (!this._idToken) throw Error("_emitAuthenticated: this._idToken null");


    this.log.info('  Emitting AUTHENTICATED');
    this.log.debug('    PlayerInfo: ', JSON.stringify(this.playerInfo));
    this.emit(AuthController.AUTHENTICATED);
  }


  /**
   * Generates a nickname for a player.
   * @param player - The player for whom to generate a nickname.
   * @returns  - The generated nickname.
   */
  _nicknamePlayerItem(player: any): string {
    if (player.nickname) {
      return player.nickname;
    }
    var playerName = '';
    if (player.given_name) playerName += player.given_name;
    if (player.given_name && player.family_name) playerName += ' ';
    if (player.family_name) playerName += player.family_name[0];

    return playerName;
  }
}

export { AuthController };
