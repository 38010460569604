
import { GameRules } from '../globals';

import {
    AttackRangeStrategy,
    CompositeAttackRangeStrategy,
    ConeAttackRangeStrategy,
    OverlappingAttackRangeStrategy,
    RadiusAttackRangeStrategy
} from '../attackRangeStrategy';

import {
    ApplyAttackMultiplierStrategy,
    AssertComplete,
    AssertPlayed,
    AttackWinsMetaStrategy,
    ClaimFlagCellStrategy,
    CollectFlagCellStrategy,
    CompositeStrategy,
    EndGameFlagCountStrategy,
    EndGameLowWaterMarkLoserStrategy,
    EndGameMaxTurnsStrategy,
    GamePlayStrategy,
    HighScoreWinsMetaStrategy,
    IfThenStrategy,
    IncrementAttackMultiplierStrategy,
    KnockBackPlayStrategy,
    MinMaxAttackMultiplierStrategy,
    MinMaxEndTurnStrategy,
    PlayTypeCombinationConditionalStrategy,
    RemovePlayedBonusCellsStrategy,
    SetEndTurnScoreStrategy,
    TurnPointsEqualsWordPointsStrategy,
    UnplaceLettersStrategy,
    UpdatePositionStrategy,
    WordLengthBonusStrategy
} from '../gamePlayStrategy';

import { scoreForLetter } from '../gameLogic/letters/scoreForLetter';

import { PlayType } from '../PlayType';
import { WordScoreStrategy } from '../gamePlayStrategy/score/WordScoreStrategy';

type AttackRangeStrategyMap = {
    [key: string]: (params) => AttackRangeStrategy;
};

type GamePlayStrategyyMap = {
    [key: string]: (params) => GamePlayStrategy;
};

export class GameRuleLogic {
    attackRangeStrategy: AttackRangeStrategy;
    gamePlayStrategy: GamePlayStrategy;

    /**
     * Takes a GameRules object and creates the appropriate attack range and game play strategies
     * @param gameRules 
     */
    constructor(gameRules: GameRules, cellSideCount: 4|6) {
        this.attackRangeStrategy= GameRuleLogic.AttackRangeStrategies[gameRules.attackRangeStrategyName]( {
            ...gameRules.attackRangeStrategyParameters,
            cellSideCount,
        })
        this.gamePlayStrategy= GameRuleLogic.GamePlayStrategies[gameRules.gamePlayStrategyName]( {
            ...gameRules.gamePlayStrategyParameters,
            maxGameScore: gameRules.maxGameScore,
            maxTurns: gameRules.maxTurns,
            scoreForLetter,
        })
        
    }

    static GamePlayStrategies : GamePlayStrategyyMap = {}

    static {
        GameRuleLogic.GamePlayStrategies['2016_GamePlay'] = (params) => {
            return new CompositeStrategy([
                new AssertPlayed(),
                
                new WordScoreStrategy(params.scoreForLetter),
                new UnplaceLettersStrategy(),
                new RemovePlayedBonusCellsStrategy(),
                new TurnPointsEqualsWordPointsStrategy(),
                new WordLengthBonusStrategy([
                    { from: 1, to: 1, bonus: 1 },
                    { from: 2, to: 2, bonus: 2 },
                    { from: 3, to: 3, bonus: 3 },
                    { from: 4, to: 4, bonus: 4 },
                    { from: 5, to: 5, bonus: 5 },
                    { from: 6, to: 6, bonus: 6 },
                    { from: 7, to: 7, bonus: 7 },
                    { from: 8, to: 8, bonus: 8 },
                    { from: 9, to: 9, bonus: 9 },
                    { from: 10, to: 99, bonus: 20 },
                ]),
                new ApplyAttackMultiplierStrategy(),

                // turn victory conditions
                new IfThenStrategy({
                    ifTrue: new PlayTypeCombinationConditionalStrategy([PlayType.MOVE, PlayType.MOVE]),
                    thenDo: []
                }),
                new IfThenStrategy({
                    ifTrue: new PlayTypeCombinationConditionalStrategy([PlayType.ATTACK, PlayType.MOVE]),
                    thenDo: new AttackWinsMetaStrategy({
                        winner: (_: any) => (0),
                        loser: (_: any) => ((_.loser.turnPoints ?? 1) - (_.winner.turnPoints ?? 1)),
                    })
                }),
                new IfThenStrategy({
                    ifTrue: new PlayTypeCombinationConditionalStrategy([PlayType.ATTACK, PlayType.ATTACK]),
                    thenDo: new HighScoreWinsMetaStrategy({
                        winner: (_: any) => (0),
                        loser: (_: any) => (-1 * (_.winner.turnPoints ?? 1)),
                    })
                }),
                new SetEndTurnScoreStrategy(),
                new MinMaxEndTurnStrategy(-999, params.maxGameScore),
                
                new IncrementAttackMultiplierStrategy(2, -99),
                new MinMaxAttackMultiplierStrategy(0, 8),
                
                new UpdatePositionStrategy(),
                new KnockBackPlayStrategy(),

                new AssertComplete(),

                new EndGameLowWaterMarkLoserStrategy(0),
                new EndGameMaxTurnsStrategy(params.maxTurns),
            ])
        }

        /**
         * 2016_GamePlay + Collectable_Flags
         * 
         * Each board flag can be collected by each player
         * There is no way to lose a flag once collected
         * A player collecting all flags wins the game
         */
        GameRuleLogic.GamePlayStrategies['2024_Collectable_Flags'] = (params) => {
            return new CompositeStrategy([
                new CollectFlagCellStrategy(),
                GameRuleLogic.GamePlayStrategies['2016_GamePlay'](params),
                new EndGameFlagCountStrategy(),
            ])
        }
   
        /**
         * 2016_GamePlay + Claimable_Flags
         * 
         * Each board flag can be Claimed by only one player
         * An opposing player may reclaim a flag
         * A player Claiming all flags wins the game
         * 
         * @deprecated Needs to be retested before use
         */
        GameRuleLogic.GamePlayStrategies['2024_Claimable_Flags'] = (params) => {
            return new CompositeStrategy([
                new ClaimFlagCellStrategy(),
                GameRuleLogic.GamePlayStrategies['2016_GamePlay'](params),
                new EndGameFlagCountStrategy(),
            ])
        } }

    static AttackRangeStrategies: AttackRangeStrategyMap = {
        // A cone-shaped attack range, angle increases (0°-45°) with word length
        '2024_ExpandingCone': ( params ) => {
            return new CompositeAttackRangeStrategy( params.cellSideCount, [
                { minWordLength: 1, maxWordLength: 5, strategy: new ConeAttackRangeStrategy(params.cellSideCount, 0) },
                { minWordLength: 6, maxWordLength: 7, strategy: new ConeAttackRangeStrategy(params.cellSideCount, 11.3) },
                { minWordLength: 8, maxWordLength: 9, strategy: new ConeAttackRangeStrategy(params.cellSideCount, 22.5) },
                { minWordLength: 10, maxWordLength: 99, strategy: new ConeAttackRangeStrategy(params.cellSideCount, 45) },
            ]);
        },

        // A cone-shaped attack range, angle increases (0°-34°) with word length - narrower than 2024_ExpandingCone
        '2024_07_ExpandingCone': ( params ) => {
            return new CompositeAttackRangeStrategy( params.cellSideCount, [
                { minWordLength: 1, maxWordLength: 5, strategy: new ConeAttackRangeStrategy(params.cellSideCount, 0) },
                { minWordLength: 6, maxWordLength: 7, strategy: new ConeAttackRangeStrategy(params.cellSideCount, 12) },
                { minWordLength: 8, maxWordLength: 9, strategy: new ConeAttackRangeStrategy(params.cellSideCount, 24) },
                { minWordLength: 10, maxWordLength: 99, strategy: new ConeAttackRangeStrategy(params.cellSideCount, 35) },
            ]);
        },

        // The lollipop - straight line, with an increasing blast radius at the end of the word
        '2016_lollipop': ( params ) => {
            return new CompositeAttackRangeStrategy(params.cellSideCount, [
                { minWordLength: 1, maxWordLength: 99, strategy: new OverlappingAttackRangeStrategy(params.cellSideCount, ) },
                { minWordLength: 6, maxWordLength: 7, strategy: new RadiusAttackRangeStrategy(params.cellSideCount, 1) },
                { minWordLength: 8, maxWordLength: 9, strategy: new RadiusAttackRangeStrategy(params.cellSideCount, 2) },
                { minWordLength: 10, maxWordLength: 99, strategy: new RadiusAttackRangeStrategy(params.cellSideCount, 3) },
            ]);
        },
    }
}
