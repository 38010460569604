import { JQuery } from "../lib/jQuery";

import { CellModel } from "./CellModel";
import { View } from "./View";

export const CELL_CLICKED = 'cellClicked';

export class CellView extends View {
    private _ui
    private _uiPlayedLetterSpan
    private _uiBonusDiv

    model: CellModel

    constructor(cellModel: CellModel) {
        super();
        this.model = cellModel;

        this._ui = JQuery('<td>')
            .off('click')
            .on('click', () => {
                this.emit(CELL_CLICKED, this)
            })
        let div = JQuery('<div>', { class: "content" })
        this._ui.append(div)
        this._uiPlayedLetterSpan = JQuery('<span>', { class: "playedLetter" })
        div.append(this._uiPlayedLetterSpan)
        if (cellModel.bonusType != null) {
            this._uiBonusDiv = JQuery('<div>').addClass('bonus')
            div.append(this._uiBonusDiv)
        }

        this.updateView()
    }

    static getCellViewTableBody(
        cellTable: CellModel[][]
    ): JQuery {
        let tbody = JQuery('<tbody>')
        for (const cellRow of cellTable) {
            let row = JQuery('<tr>')
            tbody.append(row);
            for (const cell of cellRow) {
                row.append(cell.cellView._ui)
            }
        }
        return tbody;
    }

    getCellViewElement(): JQuery {
        return this._ui
    }

    static onAllCells(
        cellTable: CellModel[][],
        eventName: string,
        eventHandler: (cellView: CellView) => void,
    ) {
        for (const cellRow of cellTable) {
            for (const cell of cellRow) {
                cell.cellView.on(eventName, eventHandler)
            }
        }
    }

    private setBonus(bonusType: string | null, bonusNumberValue: number, show: boolean) {
        if (bonusType == null) return

        let bonusValue = bonusNumberValue.toString();
        this._ui
            .addClass(`bonus ${bonusType}`)
            .attr('ww_value', bonusValue)

        this._uiBonusDiv
            .attr('class', null)
            .addClass('bonus')
            .addClass(bonusType)
            .empty()
        if (show)
            this._uiBonusDiv
                .append(
                    JQuery('<span>', {
                        class: "bonusValue"
                    }).text(`x${bonusValue}`))
                .append('<br />')
                .append(
                    JQuery('<span>', {
                        class: "bonusType"
                    }).text(bonusType))
    }

    updateView() {
        this._ui.attr('class', null)
        this._ui.attr('ww_value', null)

        this._ui.addClass(this.model.playedBy == null ? 'unplayed' : `played played-${this.model.playedBy}`)
        if (null != this.model.playerIndex) this._ui.addClass(`player-${this.model.playerIndex}`)

        // sort these alphabetically for spec tests
        if (this.model.isBlock) this._ui.addClass('block')
        if (this.model.hasFlag) this._ui.addClass('flag')
        if (this.model.isPlaceable) this._ui.addClass('placeable')
        if (this.model.isAttackable) this._ui.addClass('attackable')
        if (this.model.isPlaced) this._ui.addClass('placed')
        if (this.model.isStatic) this._ui.addClass(`static`).attr('ww_value', this.model.currentLetter)
        this.model.flagClaims?.forEach( playerIndex => this._ui.addClass(`claimed-${playerIndex}`) )

        this.setBonus(this.model.bonusType, this.model.bonusValue, (this.model.currentLetter?.length === 0))

        this._uiPlayedLetterSpan.text(this.model.currentLetter)
    }

    flashPlaceable() {
        this.flash(this._ui, 'flash-placeable')
    }

    flashPlayed(playerIndex: number, delayMs: number = 0) {
        setTimeout(() => {
            this.flash(this._ui, `flash-played-${playerIndex}`)
        }, delayMs)
    }

    flashAttacking(delayMs: number = 0) {
        setTimeout(() => {
            this.flash(this._ui, 'flash-attacking')
        }, delayMs)
    }

    flashAttack() {
        this.flash(this._ui, 'flash-attack')
    }
}
