import { log } from './lib/log';

export class AudioProxy {
    log: log.Logger;

    private audio: { [key: string]: {
        audio: HTMLAudioElement,
        delayDelta: number,
        duration: number | undefined,
    } }
    private audioDuration: { [key: string]: number } = {}

    constructor() {
        this.log = log.getLogger(this.constructor.name);
        this.log.setLevel(log.levels.DEBUG)

        this.audio = { // eager-load the audio
            'bonk': { audio: new Audio('/audio/bonk.mp3'), delayDelta : 0, duration: undefined},
            'loseRetro': { audio: new Audio('/audio/loseRetro.mp3'), delayDelta : 0, duration: undefined},
            'mouseClick': { audio: new Audio('/audio/mouseClick.mp3'), delayDelta : 0, duration: undefined},
            'pickup': { audio: new Audio('/audio/pickup.mp3'), delayDelta : 0, duration: undefined},
            'punch': { audio: new Audio('/audio/punch.mp3'), delayDelta : -300, duration: undefined},
            'putdown': { audio: new Audio('/audio/putdown.mp3'), delayDelta : 0, duration: undefined},
            'putdownmultiple': { audio: new Audio('/audio/putdownmultiple.mp3'), delayDelta : 0, duration: undefined},
            'wau': { audio: new Audio('/audio/wau.mp3'), delayDelta : 0, duration: undefined},
            'winSpacey': { audio: new Audio('/audio/winSpacey.mp3'), delayDelta : 0, duration: undefined},
        }
        Object.keys(this.audio).forEach((audioKey) => {
            this.audio[audioKey].audio.onloadedmetadata = () => {
                this.audio[audioKey].duration = Math.round(this.audio[audioKey].audio.duration)
            }
        })
    }

    /**
     * Play audio
     * @param audioKey the name of the audio
     * @param delayMs delay before play
     * @returns when the audio will finish playing
     */
    play(audioKey: string, delayMs: number = 0): number {
        let delay = Math.max( delayMs + this.audio[audioKey].delayDelta, 0)
        setTimeout(() => {
            this.audio[audioKey].audio.play();
        }, delay)
        let result = delay + (this.audio[audioKey].duration || 0)
        this.log.debug(`${this.constructor.name}.play(${audioKey}, ${delayMs}ms) => ${result}ms`)
        return result
    }

    newGame(delayMs: number = 0): number {
        return this.play('wau', delayMs);
    }

    selectTile(delayMs: number = 0): number {
        return this.play('pickup', delayMs);
    }

    placeTile(delayMs: number = 0): number {
        return this.play('putdown', delayMs);
    }

    resetWord(delayMs: number = 0): number {
        return this.play('putdownmultiple', delayMs);
    }

    invalidWord(delayMs: number = 0): number {
        return this.play('bonk', delayMs);
    }

    playWord(delayMs: number = 0): number {
        return this.play('mouseClick', delayMs);
    }

    executeCompletedTurn(delayMs: number = 0): number {
        return this.play('wau', delayMs);
    }

    move(delayMs: number = 0): number {
        return this.play('wau', delayMs);
    }

    attack(delayMs: number = 0) : number {
        return this.play('punch', delayMs);
    }

    win(delayMs: number = 0): number {
        return this.play('winSpacey', delayMs);
    }

    lose(delayMs: number = 0): number {
        return this.play('loseRetro', delayMs);
    }
}
