import { Play } from '../../Play';
import { GamePlayStrategy } from '../GamePlayStrategy';

export class TurnPointsEqualsWordPointsStrategy implements GamePlayStrategy {
    execute(plays: Play[], _args: any): boolean {
        plays.forEach(play => {
            if (null == play.wordPoints) throw new Error("TurnPointsEqualsWordPointsStrategy.execute() wordPoints is null")

            play.turnPoints = play.wordPoints;
        })
        return true; // return true if the function executes successfully
    }
}
