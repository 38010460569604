import { log } from './lib/log';

import { EventEmitter } from './lib/EventEmitter'

import { AuthController } from './auth/AuthController'
import { BackButtonView } from './ui/BackButtonView'
import { GameController } from './ui/GameController'
import { GameListController } from './ui/GameListController'
import { GameListView } from './ui/GameListView'
import { GameView } from './ui/GameView'
import { RemoteProxy } from './RemoteProxy'
import { StatusView } from './ui/StatusView.ts'
import { BoardTesterView } from './ui/BoardTesterView.ts';
import { BoardEditorView } from './ui/BoardEditorView.ts';
import { GameTemplate } from './globals';
import { CreateGameView } from './ui/CreateGameView.ts';

/**
 * The main controller of the application.
 */
class MainController extends EventEmitter {
    protected log : log.Logger = log.getLogger(this.constructor.name);

    private _window: Window;
    private _authController: AuthController;
    remote: RemoteProxy;

    glc!: GameListController;
    gameView!: GameView;
    private _bbv!: BackButtonView;
    gc!: GameController;

    statusView: StatusView;
    createGameView: CreateGameView;

    constructor(authController: AuthController, window: Window, config: any) {
        super();
        this.log.setLevel(log.levels.DEBUG)

        this.log.info(this.constructor.name, '.<constructor>(..)');

        this._window = window;
        this._window.onload = () => this.handleWindowLoad();
        this._window.onhashchange = () => this.handleHashChange();

        this._authController = authController;        
        this.remote = new RemoteProxy(authController, config.restBaseUrl);

        // let RemoteProxy register for auth events before MainController
        this._authController.on(AuthController.AUTHENTICATED, () => this.handleAuthenticated());
    }

    handleWindowLoad() {
        this.log.info(this.constructor.name, '.handleWindowLoad()');

        this.statusView = new StatusView();
        this.createGameView = new CreateGameView();
        this.createGameView.on(CreateGameView.CREATE_NEW_GAME, gameTemplate => this.handleCreateOrJoinGame(gameTemplate))
        this.createGameView.on(CreateGameView.EDIT_BOARD, boardPath => this.showEditBoard(boardPath))

        this.remote.getStatus(window.location.origin)
        .then((status: any) => {
            this.log.info('SERVER STATUS:', JSON.stringify(status, null, 2));
            this.statusView.setStatus(status);
        })

        this.glc = new GameListController(new GameListView());
        this.glc.setRemote(this.remote);
        this.glc.on(GameListController.PROMPT_NEW_GAME, () => this.handlePromptNewGame())
        this.glc.on(GameListController.JOIN_GAME, () => this.handleCreateOrJoinGame())
        this.glc.on(GameListController.GAME_SELECTED, gameId => this.handleGameSelected(gameId))

        this.gameView = new GameView();

        this._bbv = new BackButtonView();
        this._bbv.on('CLICK', () => this._backToList())

        this.glc.setVisibility(true);
        this.gameView.setVisibility(false);

        let hash = this._window.location.hash;
        this._authController.onWindowLoad(hash);
        this.handleHashChange();
    }

    handleHashChange() {
        let hash = this._window.location.hash;
        this.log.info(`${this.constructor.name}.handleWindowLoad() - hash= ${hash}`);

        if (hash.startsWith('#edit')) {
            let boardPath = hash.slice("#edit".length);
            this.showEditBoard(boardPath.length > 0 ? boardPath : undefined);
        } else if (hash.startsWith('#test4')) {
            this.showTestBoard(4)
        } else if (hash.startsWith('#test')) {
            this.showTestBoard(6)
        }
    }

    showLogin() {
        this.log.info(this.constructor.name, `.showLogin()`);
        this._authController.login();
    }

    handleAuthenticated() {
        this.log.info(this.constructor.name, '.handleAuthenticated()');

        this.glc.getGames();
        this.gc = this._getGame();
    }

    _getGame() {
        this.log.info(this.constructor.name, '._getGame()');

        return new GameController(this.remote, this.gameView);
    }

    /**
     * A game has been selected.
     *
     * @param gameId - The ID of the game to be selected.
     */
    handleGameSelected(gameId: string) {
        this.log.info(this.constructor.name, '.handleGameSelected( gameId =', gameId, ')');
        this.gc.loadGame(gameId);
        this.glc.setVisibility(false);
        this.gameView.setVisibility(true);
    }

    handlePromptNewGame() {
        this.log.info(this.constructor.name, '.handleCreateOrJoinGame()');
        this.glc.setVisibility(false);
        this.gameView.setVisibility(false);
        this.createGameView.isVisible = true;
    }

    handleCreateOrJoinGame(gameTemplate: GameTemplate | {} = {}) {
        this.log.info(this.constructor.name, '.handleCreateOrJoinGame(.)');
        this.glc.setVisibility(false);
        this.gameView.setVisibility(true);
        this.createGameView.isVisible = false;
        this.gc.createOrJoinGame(gameTemplate);
    }

    _backToList() {
        this.log.info(this.constructor.name, '._backToList()');
        this.glc.getGames();
        this.glc.setVisibility(true);
        this.gc.setVisibility(false);
        this.createGameView.isVisible = false;
    }

    /**
     * Cheat function to get the letters of the game.
     */
    __getLetters() {
        console.log(this.gc._lettersModel.toString())
    }

    /**
     * Debug function - render a test board.
]    */
    showTestBoard(cellSideCount: 4 | 6 = 6) {
        // reload css
        (document.querySelectorAll("link[rel=stylesheet]") as NodeListOf<HTMLLinkElement>).forEach(link => link.href = link.href.replace(/\?.*|$/, "?" + Date.now()))

        this.createGameView.isVisible = false;
        this.glc.setVisibility(false);
        this.gameView.setVisibility(true);
        this.gc._boardView = new BoardTesterView(cellSideCount, this.gc._boardView);
    }

    /**
     * Debug function - render a board editor.
     */
    showEditBoard(boardPath : string = '/boards/hex-5x7-blank.txt', cellSideCount: 4 | 6 = 6) {
        // reload css

        this.createGameView.isVisible = false;
        this.glc.setVisibility(false);
        this.gameView.setVisibility(true);
        let boardEditor = new BoardEditorView(cellSideCount, this.gc._boardView);
        this.gc._boardView = boardEditor;

        this.remote.loadHtml(boardPath)
            .then(boardText => boardEditor.loadBoardTemplate(boardText))
    }
}


/**
 * The entry point of the application.
 * 
 * @param config - The configironment configuration.
 * @returns {MainController} The main controller instance.
 */
export async function main(config: any) {
    log.info('main( config: ', JSON.stringify(config), ')');
    const auth0Controller = await AuthController.getAuthController(config.authConfig);
    return new MainController(auth0Controller, window, config);
}
