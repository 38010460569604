import { log } from '../../lib/log';

import { Play } from '../../Play';
import { GamePlayStrategy } from '../GamePlayStrategy';

export class CompositeStrategy implements GamePlayStrategy {
    private _StrategyList: GamePlayStrategy[];
    private log: any = log.getLogger(this.constructor.name);

    constructor(StrategyList: GamePlayStrategy[]) {
        this.log.setLevel(log.levels.INFO)
        this._StrategyList = StrategyList;
    }

    execute(plays: Play[], args: any): boolean {
        let allTrue = true;
        this._StrategyList.forEach((subStrategy: GamePlayStrategy) => {
            let result = subStrategy.execute(plays, args);
            this.log.debug(this.constructor.name, '.execute (', plays, ') sub-strategy:', subStrategy.constructor.name, 'result:', result)
            allTrue = allTrue && result;
        });
        return allTrue;
    }
}
