import { EventEmitter } from '../lib/EventEmitter';
import { log } from '../lib/log';

import { AudioProxy } from '../AudioProxy';
import { BoardController } from './BoardController'
import { BoardModel } from './BoardModel'
import { BoardView } from './BoardView'
import { ButtonsView } from './ButtonsView';
import { Game } from '../globals';
import { GameEvent } from './GameEvent';
import { GameState, getGameStates } from './GameControllerState';
import { GameView } from './GameView';
import { LettersModel } from './LettersModel';
import { LettersView } from './LettersView';
import { Play } from '../Play';
import { PlayType } from '../PlayType';
import { RemoteProxy } from '../RemoteProxy';
import { ScoreView } from './ScoreView';
import { TurnView } from './TurnView';
import { GameRuleLogic } from '../gameLogic/GameRuleLogic';
import { scoreForLetter } from '../gameLogic/letters/scoreForLetter';

export enum GameControllerEvent {
	GAME_INFO,
	LOCAL_PLAYER_INDEX,
	NEW_TURN,
	END_TURN,
}

const DELAY_LETTER_PLACE = 30; // ms - delay between animating each letter
const DELAY_WORD_PLACE = 200; // ms - delay after placing the word
const DELAY_WORD_UNPLACE = 400; // ms - delay before removing the word
const DELAY_POST_MOVE = 500; // ms - delay after Move finished
const DELAY_POST_ATTACK = 2000; // ms - delay after Attack finished
const DELAY_POST_PLAYS = 800; // ms - delay after all plays finished

export class GameController extends EventEmitter {
	static END_OF_GAME = 999; // turnNumber to indicate end of game

	log: log.Logger

	gameStates = getGameStates(this)
	_gameState: GameState

	_gameInfo: Game | null
	_localPlayerIndex: number

	_gameRuleLogic: GameRuleLogic;

	_wordDictionary: string[]

	_letterTiles: string[]

	_plays: Play[][]
	turnIndex: number

	_intervalId: any
	_intervalDelay?: number = undefined

	_audio: AudioProxy = new AudioProxy();
	_remote: RemoteProxy;

	// sibling MVC components

	_gameView: GameView;

	_lettersModel: LettersModel = new LettersModel(scoreForLetter);
	_lettersView: LettersView = new LettersView(this._lettersModel);
	_buttonsView: ButtonsView = new ButtonsView(this);

	_boardModel: BoardModel = new BoardModel();
	_boardView: BoardView = new BoardView(this._boardModel);
	_boardController: BoardController = new BoardController({
		boardModel: this._boardModel,
		boardView: this._boardView,
		lettersModel: this._lettersModel,
		lettersView: this._lettersView,
		buttonsView: this._buttonsView,
		playEmitter: this,
		gameController: this,
	});

	_scoreView: ScoreView = new ScoreView(this);
	_turnView: TurnView = new TurnView(this);

	constructor(
		remoteProxy: RemoteProxy,
		gameView: GameView,
	) {
		super();
		this.log = log.getLogger(this.constructor.name);
		this.log.setLevel(log.levels.DEBUG)
		this.log.info(this.constructor.name, '.constructor(...)')

		this._remote = remoteProxy;
		this._gameView = gameView;

		this._resetGame()

		// remote bindings
		this._remote.on(GameEvent.GAME_INFO, gameInfo => this.gameState.handleReceiveGame(gameInfo))
		this._remote.on(GameEvent.PLAY_INFO, ({ gameId, playList }) => {this.handleReceivePlays({ gameId, playList })})

		// UI bindings
		this._buttonsView.on('btnMove', () => this.playWord(PlayType.MOVE));
		this._buttonsView.on('btnAttack', () => this.playWord(PlayType.ATTACK));
		this._buttonsView.on('btnReset', () => this.gameState.handleClickReset());
		this._lettersView.on(LettersView.TILE_SELECTED,
			({ index, letter }) => this.handleTileSelected(index, letter));

	}

	public get gameState() {
		return this._gameState
	}

	/**
	 * Sets the game state and updates the UI accordingly.
	 *
	 * @param gameState - The new game state.
	 * @param Optional delay in milliseconds before updating the UI. Default: 0 = no delay
	 */
	public setGameState(gameState: GameState, delay: number = 0) {
		this.log.info(`${this.constructor.name}.setGameState( ${gameState.name}, delay=${delay} )`)

		if (this._gameState !== gameState) {
			this._gameState = gameState

			if (delay > 0) {
				this.log.trace(`${this.constructor.name}.setGameState() -> _updateVisibleGameState delay=${delay}ms`)
				setTimeout(() => this._updateVisibleGameState(), delay);
			} else {
				this._updateVisibleGameState();
			}
		}
	}

	_updateVisibleGameState() {
		this.log.info(this.constructor.name, '._updateVisibleGameState()')
		this.log.debug(`${this.constructor.name}._updateVisibleGameState() - gameState=${this._gameState.constructor.name}	(${this._gameState.statusText})`)
		this._boardView.setStatus(this._gameState.statusText)
		this._boardView.setStatusMood(this._gameState.statusName)
		this._gameState.updateViews({ buttonsView: this._buttonsView, lettersView: this._lettersView })

		this._poll()
	}

    /**
     * Receive a list of Play objects (from remote). Store them and update the game state.
     * @param gameId - The ID of the game.
     * @param playList - The list of plays.
	 * @returns true if the plays were updated, false otherwise
     */
    protected handleReceivePlays({ gameId, playList }: { gameId: String, playList: Play[] }): boolean {
        this.log.info(`GameState.handleReceivePlays( gameId=${gameId}, playlist=Array(${playList.length}) )`)
        if (gameId !== this._gameInfo?.gameId) {
            log.error(`handleReceivePlays() - received plays for game ${gameId} but we are in game ${this._gameInfo?.gameId}`)
            return false
        }

        let hasUpdates = false
        playList.forEach(play => {
			play.assertPlayed()
            if (!this._plays[play.turnIndex]) this._plays[play.turnIndex] = [];
            if (!this._plays[play.turnIndex][play.playerIndex]?.equals(play)) {
                this._plays[play.turnIndex][play.playerIndex] = play
                hasUpdates = true
            }
        })
        this.log.debug(`GameState.handleReceivePlays( gameId=${gameId}, playlist=Array(${
            playList.length
        }) ) - hasUpdates=${
            hasUpdates
        } - plays= (${
            this._plays.length
        })[Array(${
            this._plays[this._plays.length-1]?.length ?? 0
        })] `)
        if (hasUpdates) {
            this.checkGameReady()
        }
        return hasUpdates
    }

	setVisibility(isVisible: boolean) {
        this._gameView.setVisibility(isVisible);
		if (isVisible ) {
			this._boardView.setStatusMood(this._gameState.statusName)
		} else {
			this._boardView.setStatusMood(this.gameStates.NO_GAME.statusName)
		}
    }

    /**
     * Join an existing game or create a new one.
     * @param gameTemplate - Optional game template definition, if specified will always create a new game.
     */
    createOrJoinGame(gameTemplate: any = {}) {
		this._resetGame();
		this.setGameState( this.gameStates.NOT_STARTED )
		this._remote.createOrJoinGame(gameTemplate)
	}

	/**
	 * Request remote to load an existing game
	 * @param gameId Id of the game to be loaded
	 */
	loadGame(gameId: string) {
		this._resetGame();
		this.setGameState( this.gameStates.NOT_STARTED )
		this._remote.loadGame(gameId);
	}

	/**
	 * Check if we have all the data we need for the Game to start/continue
	 * If so, start the game
	 */
	checkGameReady(): void {
		this.log.info(this.constructor.name, '.checkGameReady(.)');

		// if we aren't ready yet, log reason and return
		if (!this._boardModel.isLoaded) return this.log.debug(this.constructor.name, '.checkGameReady() - board is not loaded');
		if (this._wordDictionary.length === 0) return this.log.debug(this.constructor.name, '.checkGameReady() - word dictionary is not loaded');
		if (this._gameInfo?.playerList.length !== this._gameInfo?.maxPlayerCount) return this.log.debug(this.constructor.name, '.checkGameReady() - player count does not match max player count');
		if (this._localPlayerIndex < 0) return this.log.debug(this.constructor.name, '.checkGameReady() - local player index not set');
		this._gameInfo?.lastPlayedTurnIndexList.forEach((_turnIndex, playerIndex) => {
			let turnIndex = (_turnIndex == GameController.END_OF_GAME ? 0 : _turnIndex) // if the game has ended, check that we at least have the first play
			if (! this._plays[turnIndex]?.[playerIndex]) return this.log.debug(`${this.constructor.name}.checkGameReady() - plays[turn ${turnIndex}][player ${playerIndex}]not loaded yet`);
		})

		this.gameReady()
	}

	/**
	 * We have all the data we need for the Game to start/continue.
	 */
	gameReady() {
		this.log.info(this.constructor.name, '.gameReady()');

		let delay = this.executeAllCompleteTurns();
		this.log.trace(`${this.constructor.name}.gameReady() - _updateVisibleGameState -> delay=${delay}ms`)


		this.emit(GameControllerEvent.GAME_INFO, this._gameInfo);
		this.emit(GameControllerEvent.LOCAL_PLAYER_INDEX, this._localPlayerIndex);

		setTimeout(() => {
			this._boardView.flashPlayer(this._localPlayerIndex);
			this._scoreView.flashScore(this._localPlayerIndex);
		}, delay);
	}

	/**
	 * Create new Play objects for the given turn index.
	 * @param turnIndex - The index of the turn.
	 * @returns The turns created
	 */
	createPlaysForTurn(turnIndex: number): Play[] {
		if (!this._gameInfo) throw new Error("gameInfo not set")
		this._plays[turnIndex] = this._plays[turnIndex] || [];
		for (let playerIndex = 0; playerIndex < this._gameInfo?.maxPlayerCount; playerIndex++) {
			let play = this._plays[turnIndex][playerIndex];
			if (!play) { // don't override preloaded (allow us to replay multiple turns)
				if (turnIndex === 0) { // bootstrap first turn
					play = Play.createFirstPlay(this._gameInfo, playerIndex, this._boardModel.getPlayerCell(playerIndex).coordinates);
				} else { // otherwise, from previous turn
					play = this._plays[turnIndex - 1][playerIndex].createNextTurnPlay();
				}

				this._plays[turnIndex][playerIndex] = play;
				this.emit(GameControllerEvent.NEW_TURN, play);
			}
			if (this._plays[turnIndex][playerIndex].playerIndex != playerIndex) throw new Error(`playerIndex mismatch on this._plays[${turnIndex}][${playerIndex}].playerIndex = ${this._plays[turnIndex][playerIndex].playerIndex}`);
		}
		return this._plays[turnIndex];
	}

	/**
	 * Returns the play count for a given turn index.
	 *
	 * @param turnIndex - The index of the turn.
	 * @returns The play count for the specified turn index.
	 */
	_getPlayedPlayCountForTurn(turnIndex: number = this.turnIndex): number {
		let playsForThisTurn = this._plays[turnIndex];
		if (!playsForThisTurn) return 0;
		let count = playsForThisTurn.filter((play: Play) => play?.isPlayed).length;
		let maxPlayerCount = this._gameInfo?.maxPlayerCount ?? -1;
		if (count > maxPlayerCount) throw new Error(`play count ${count} exceeds max player count ${maxPlayerCount} for turn ${turnIndex}`);
		return count;
	}

	private isTurnPlayed(turnIndex: number): boolean {
		if (!this._plays[turnIndex]) return false
		return (this._getPlayedPlayCountForTurn(turnIndex) === this._gameInfo?.maxPlayerCount);
	}

	/**
	 * Based on the current turn index, execute all the completed turns.
	 */
	executeAllCompleteTurns(): number {
		this.log.info(this.constructor.name, '.executeAllCompleteTurns() - turnIndex =', this.turnIndex);

		this.setGameState( this.gameStates.ANIMATING, 0 )

		let delay = 0;
		let isLatestTurn = false
		while (this.isTurnPlayed(this.turnIndex) && !isLatestTurn) {
			isLatestTurn = !this.isTurnPlayed(this.turnIndex + 1);
			delay = this.executeCompletedTurn(this.turnIndex, isLatestTurn);
			this.log.trace(`${this.constructor.name}.executeAllCompleteTurns() - executeCompletedTurn -> delay=${delay}ms`)
			if (this.checkForGameEnd(this._plays[this.turnIndex], delay)) {
				return delay;
			}
			this.turnIndex++;
		}
		this.log.debug(this.constructor.name, '.executeAllCompleteTurns() - turnIndex =>', this.turnIndex);


		if (this._gameInfo == undefined) throw new Error("executeAllCompleteTurns() - gameInfo not set")
		const letterTiles = this._gameInfo.tilesForTurns[this.turnIndex]
		const playsForThisTurn = this.createPlaysForTurn(this.turnIndex)

		let isOurTurn = true
		for (let i = playsForThisTurn.length - 1; i >= 0; i--) {
			if (i === this._localPlayerIndex && playsForThisTurn[i].isPlayed) { // we have played
				isOurTurn = false
			}
		}

		this.log.debug(`${this.constructor.name}.executeAllCompleteTurns() - isOurTurn=${isOurTurn}`)
		if (isOurTurn) {
			this.setGameState( this.gameStates.SELECT_FIRST_TILE, delay )
		} else {
			this.setGameState( this.gameStates.REMOTE_MOVE, delay )
		}

		setTimeout(() => {
			this._lettersModel.resetLetters(letterTiles)
			this._lettersView.updateView()
			}, delay)
		return delay
	}


	/**
	 * We have all the plays for the given turn.
	 * Execute the game logic and update the board.
	 * @param turnIndex - The index of the turn.
	 * @param isLatestTurn - Do animations on latest turn
	 */
	executeCompletedTurn(turnIndex: number, isLatestTurn: boolean): number {
		this.log.info(this.constructor.name, `.executeCompletedTurn( turnIndex=${turnIndex}, isLatestTurn='${isLatestTurn}')`)
		let delay = 0 // ms

		let playsForTurn = this._plays[turnIndex];

		//
		// execute all the gamePlayStrategy logic
		//
		this._gameRuleLogic.gamePlayStrategy.execute(playsForTurn, { boardModel: this._boardModel });
		playsForTurn.forEach((play: Play) => play.assertComplete())

		if (isLatestTurn) {
			const letterTiles = this._gameInfo?.tilesForTurns[this.turnIndex] ?? ''
			
			this._lettersModel.resetLetters(letterTiles)
			this._lettersView.updateView()

			playsForTurn.forEach((play: Play) => {
				this._boardController._boardModel.setPlayerCoordinates(play.playerIndex, play.startCoords)
			})

			// sort Plays into moves and attacks
			let movePlays: Array<Play> = playsForTurn.filter((play: Play) => play.playType === PlayType.MOVE);
			let attackPlays: Array<Play> = playsForTurn.filter((play: Play) => play.playType === PlayType.ATTACK);
			if (movePlays.length + attackPlays.length !== playsForTurn.length) throw new Error("executeCompletedTurn() - playType mismatch");

			delay = 500
			this.log.debug(this.constructor.name, '.executeCompletedTurn() - pre-attacking delay=', delay)
			attackPlays.forEach((play: Play, playIndex: number) => {
				delay = this.animateAttacking(play, delay + playIndex * 1000);
				let attackedPlayerIndex = 1 - play.playerIndex // hard-coded for 2-player game
				delay = this.animateAttacked(playsForTurn[attackedPlayerIndex], delay + playIndex * 500)
				delay = this._audio.attack(delay)
				delay += DELAY_POST_ATTACK; this.log.trace(this.constructor.name, '.executeCompletedTurn() - post-attacking delay=', delay)
			})
			// show moves
			movePlays.forEach((play: Play, playIndex: number) => {
				delay = this.animateMove(play, delay + playIndex * 1000); this.log.debug(this.constructor.name, '.executeCompletedTurn() - pre-move delay=', delay)
				if (! play.lost) {
					delay = this._audio.move(delay); this.log.trace(this.constructor.name, '.executeCompletedTurn() - audio.move delay=', delay)
				}
				delay += DELAY_POST_MOVE; this.log.debug(this.constructor.name, '.executeCompletedTurn() - post-move delay=', delay)
			})
			
			setTimeout(() => {
				playsForTurn.forEach((play: Play) => {
					this._boardController._boardModel.setPlayerCoordinates(play.playerIndex, play.endCoords ?? play.startCoords)
				})
			}, delay)
			delay += DELAY_POST_PLAYS; this.log.debug(this.constructor.name, '.executeCompletedTurn() - post-plays delay=', delay)
		}

		setTimeout(() => {
			playsForTurn.forEach((play: Play) => {
				this._boardView.addPlay(play)
				this.emit(GameControllerEvent.END_TURN, play)
			})
		}, delay)

		return delay
	}

	/**
	 * Show a MOVE-play initiated by giver play(-er)
	 *
	 * @param play - The move to be executed.
	 */
	animateMove(play: Play, delayMs: number = 0): number {
		this.log.info(this.constructor.name, `.animateMove(play= { turnIndex: ${play.turnIndex}, playerIndex: ${play.playerIndex} })`)
		let delay = delayMs
		let animateWord = ' ' + play.word // left-pad as first playedRange is starting position
		this._boardModel.getCellsForCoordinates(play.playedRange).forEach((cell, index) => {
			delay += index * DELAY_LETTER_PLACE
			let letter = animateWord[index]
			setTimeout( () => {
				this._boardController._boardModel.setPlayerCoordinates(play.playerIndex, play.startCoords)
				cell.placeLetter(letter)
				cell.cellView.flashPlayed(play.playerIndex)
				this._lettersModel.placeByLetter(letter)
				this._lettersView.updateView()
			}, delay)
		})
		delay += DELAY_WORD_PLACE
		setTimeout( () => {
			this._boardView.addPlay(play)
			this._scoreView.addPlay(play)
		}, delay)
		delay += DELAY_WORD_UNPLACE
		setTimeout( () => {
			this._lettersModel.resetPlacedCells();
			this._lettersModel.unselect();
			this._lettersView.updateView()
		}, delay)
		delay += DELAY_WORD_UNPLACE
		this._boardModel.getCellsForCoordinates(play.playedRange).forEach((cell, index) => {
			delay += index * DELAY_LETTER_PLACE
			setTimeout( () => {
				this._boardController._boardModel.setPlayerCoordinates(play.playerIndex, cell.coordinates)
				cell.unplaceLetter()
			}, delay)
		})
		setTimeout( () => {
			this._boardController._boardModel.setPlayerCoordinates(play.playerIndex, play.endCoords ?? play.startCoords)
		}, delay)

		return delay
	}

	/**
	 * Show an Attack initiated by giver play(-er)
	 *
	 * @param play - The play object containing the attack information.
	 * @returns the delay in ms of the start of the last animation
	 */
	animateAttacking(play: Play, delayMs: number = 0): number {
		this.log.info(this.constructor.name, `.animateAttacking(play= { turnIndex: ${play.turnIndex}, playerIndex: ${play.playerIndex} })`)
		let delay = delayMs
		let animateWord = ' ' + play.word // left-pad as first playedRange is starting position
		this._boardModel.getCellsForCoordinates(play.playedRange).forEach((cell, index) => {
			delay += index * DELAY_LETTER_PLACE
			let letter = animateWord[index]
			setTimeout( () => {
				cell.placeLetter( letter )
				cell.cellView.flashAttacking()
				this._lettersModel.placeByLetter(letter)
				this._lettersView.updateView()
			}, delay)
			setTimeout( () => {
				cell.unplaceLetter()
			}, delay + 400)
		})
		delay += DELAY_WORD_PLACE
		setTimeout( () => {
			this._boardView.addPlay(play)
			this._scoreView.addPlay(play)
		}, delay)
		delay += DELAY_WORD_UNPLACE
		setTimeout( () => {
			this._boardView.addPlay(play)
		}, delay)
		setTimeout( () => {
			this._lettersModel.resetPlacedCells();
			this._lettersModel.unselect();
			this._lettersView.updateView()
		}, delay)
		return delay
	}

	/**
	 * Show an Attack initiated by giver play(-er)
	 *
	 * @param play - The play object containing the attack information.
	 */
	animateAttacked(play: Play, delayMs: number = 0): number {
		this.log.info(this.constructor.name, `.animateAttacked(play= { turnIndex: ${play.turnIndex}, playerIndex: ${play.playerIndex} })`)
		setTimeout(() => {
			this._boardView.flashAttackOnPlayer(play.playerIndex)
		}, delayMs)
		return delayMs
	}

	/**
	 * Checks if a word is valid.
	 *
	 * @param word - The word to be checked.
	 * @returns true if the word is valid, otherwise false.
	 */
	validWord(word: string) {
		return GameController.binaryIndexOf(this._wordDictionary, word) >= 0;
	}

	// http://oli.me.uk/2013/06/08/searching-javascript-arrays-with-a-binary-search/
	private static binaryIndexOf(searchArray: Array<any>, searchElement: any) {
		let minIndex = 0;
		let maxIndex = searchArray.length - 1;
		let currentIndex : number;
		let currentElement : any;

		while (minIndex <= maxIndex) {
			currentIndex = (minIndex + maxIndex) / 2 | 0;
			currentElement = searchArray[currentIndex];

			if (currentElement < searchElement) {
				minIndex = currentIndex + 1;
			}
			else if (currentElement > searchElement) {
				maxIndex = currentIndex - 1;
			}
			else {
				return currentIndex;
			}
		}

		return -1;
	}

	/**
	 * User has clicked on a tile. See if we can place it on the board.
	 *
	 * @param index - Index of the tile list
	 * @param letter - The letter of the tile
	 */
	handleTileSelected(index: number, letter: string): void {
		this.log.info(this.constructor.name, '.handleTileSelected(index=', index, ', letter=', letter, ')');
		this.log.debug('GameController.handleTileSelected  (this=', this, ')');

		// tile already placed; ignore
		if (this._lettersModel.isPlaced(index)) return

		var placeableCells = this._boardController.markPlaceableCells(this._localPlayerIndex);
		placeableCells.forEach(cell => {
			cell.cellView.flashPlaceable();
		});

		if (placeableCells.length === 1) {
			//
			// only one option, just auto-place next tile in the direction
			//
			this._lettersModel.select(index);
			this._boardController.placeLetterOnBoard(placeableCells[0], letter, index)

			this.gameState.handleTilePlaced()
		} else if (placeableCells.length > 1) {
			//
			// select the tile, ready for placement
			//
			this._audio.selectTile();
			this._lettersModel.select(index);
			this._lettersView.updateSelection();

			this.gameState.handleTileSelected()
		}
	}

	/**
	 * 
	 * @param playType Attack or Move
	 * @returns the played object for the local player, undefined if the word is invalid
	 */
	getPlayedPlay(playType: PlayType) : Play | undefined {
		let startingPlay = this._getCurrentPlayForPlayer()

		let word = this._boardModel.getPlayedWord(startingPlay.playerIndex)
		this.log.debug(`${this.constructor.name}.getPlayedPlay() - word = '${word}'`);
		// try in order of tiles placed
		if (!this.validWord(word)) {
			// try reverse
			if (this.validWord(startingPlay.getReversedWord(word))) {
				startingPlay.isWordReversed = true
			} else {
				return undefined
			}
		}
		let direction = this._boardModel.placedDirection

		let endOfWordCell = this._boardController.getEndOfWordCell(this._localPlayerIndex)
		if (endOfWordCell == undefined) throw new Error("End of word cell is undefined")
		let endWordCoords = endOfWordCell.coordinates

		let playedPlay = new Play({
			...(startingPlay.toJSON()),

			word,
			playType,
			direction,
			endWordCoords,
		})
		playedPlay.assertPlayed()
		return playedPlay
	}

	/**
	 * User has clicked Move or Attack - play the word in the game
	 *
	 * @param playType - The type of play.
	 */
	playWord(playType: PlayType) {
		this.log.info(`${this.constructor.name}.playWord('${playType}')`);

		
		let playedPlay = this.getPlayedPlay(playType)
		if (!playedPlay) {
			this._audio.invalidWord()
			this._boardView.flashBoard()
			return
		} else {
			this._audio.playWord()

			this._lettersModel.unselect()
			this._boardController.resetWord()

			this.handleReceivePlays({gameId: this._gameInfo?.gameId ?? '', playList: [playedPlay]})
			this._remote.executeLocalPlay(playedPlay)
		}
	}


	/**
	 * Checks if the game has ended based on the given plays.
	 * @param  plays - The list of plays made in the game.
	 * @returns - true if the game has ended, false otherwise.
	 */
	checkForGameEnd(plays: Array<Play>, delay: number): boolean {
		this.log.info(`${this.constructor.name}.checkForGameEnd(plays, delay=${delay}ms)`)

		let endGame = false;
		plays.forEach( play => {
			if (play.lost) {
				endGame = true;
				if (play.playerIndex === this._localPlayerIndex) { // if WE lost
					setTimeout( () => this._audio.lose(), delay)
					this.setGameState( this.gameStates.REMOTE_WIN, delay )
				} else { // must be the other guy
					setTimeout( () => this._audio.win(), delay)
					this.setGameState( this.gameStates.LOCAL_WIN, delay )
				}
			}
		})

		this.log.debug(this.constructor.name, '.checkForGameEnd(.) endGame = true');

		if (!endGame) {
			return false;
		}

		this._lettersView.setEnabled(false);

		if (this._gameInfo && this._gameInfo.lastPlayedTurnIndexList[this._localPlayerIndex] !== GameController.END_OF_GAME) {
			this._gameInfo.lastPlayedTurnIndexList[this._localPlayerIndex] = GameController.END_OF_GAME
			this._remote.updateGameTable(this._gameInfo)
		}

		return true
	}

	_resetGame() {
		this._gameInfo = null;
		this._localPlayerIndex = -1;
	
		this._gameRuleLogic;
	
		this._wordDictionary = [];
	
		this._letterTiles = [];
	
		this._plays = [];
		this.turnIndex = 0;
	
		this.setGameState( this.gameStates.NO_GAME )
		this._boardModel.reset();
		this._boardView.clearPlayedItems();
	}

	/**
	 * @returns the current play for the local player
	  */
	_getCurrentPlayForPlayer(): Play {
		return this._plays[this.turnIndex][this._localPlayerIndex];
	}

	/**
	 * Trigger the game state polled() function for any state-dependant polling actions
	 */
	public _poll(isCallback:boolean = false): void {
		this.log.debug(`${this.constructor.name}._poll(isCallback=${isCallback}) - intervalId = ${this._intervalId}`)
		let nextInterval = this.gameState.polled()
		if ( nextInterval !== this._intervalDelay ) {
			clearInterval(this._intervalId) // in case we are called directly
			this._intervalId = setInterval(() => this._poll(true), nextInterval)
			this._intervalDelay = nextInterval
		}
	}
}
