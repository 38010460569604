import { Play } from '../../Play'
import { PlayType } from '../../PlayType'
import { GamePlayStrategy } from '../GamePlayStrategy'

export class ApplyAttackMultiplierStrategy implements GamePlayStrategy {
    execute(plays: Play[], _args: any): boolean {
        plays.forEach(play => {
            if (null == play.playType) throw new Error("ApplyAttackMultiplierStrategy.execute() playType is null")
            if (play.playType === PlayType.ATTACK && play.turnPoints && play.startAttackMultiplier) {
                play.turnPoints *= play.startAttackMultiplier
                if (play.startAttackMultiplier !== 1) {
                    play.pushPlayEvent({ attackMultiplier: play.startAttackMultiplier })
                }
            }
        })
        return true
    }
}
