import { $ } from '../lib/jQuery';

import { EventEmitter } from '../lib/EventEmitter'
import { Play } from '../Play';
import { GameControllerEvent } from './GameController';
import { View } from './View';

type Button = {
    name: string;
    ui: JQuery<HTMLElement>;
};

export class ButtonsView extends View {

    private localPlayerIndex: number;
    private _buttons: Record<string, Button>;
    
    constructor(playEmitter : EventEmitter) {
        super();
        this.log.setLevel(this.log.levels.SILENT)

        this.localPlayerIndex = -1;

        this._buttons = {
            btnMove:   { name: 'btnMove',   ui: $('button#move') },
            btnAttack: { name: 'btnAttack', ui: $('button#attack') },
            btnReset:  { name: 'btnReset',  ui: $('button#reset') },
        };

        playEmitter.on(GameControllerEvent.LOCAL_PLAYER_INDEX, playerIndex => this._receiveLocalPlayerIndex(playerIndex))
        playEmitter.on(GameControllerEvent.NEW_TURN, play => this._receivePlay(play))

        Object.keys(this._buttons).forEach((buttonKey) => {
            var button = this._buttons[buttonKey];
            button.ui
                .off('click')
                .on('click', button, (eventObject) => {
                    this._click(eventObject.data);
                });
        });
    }

    enable(buttonName : string, isEnabled : boolean) {
        this.log.info(this.constructor.name, '.enable(' + buttonName + ', ' + isEnabled + ')');
        var button = this._buttons[buttonName].ui;

        if (button.prop('disabled') && isEnabled) {
            this.flash(button, 'flash-button-enable');
        }

        button.prop('disabled', !isEnabled);
    }

    _receiveLocalPlayerIndex(localPlayerIndex : number) {
        this.localPlayerIndex = localPlayerIndex;
    }

    _receivePlay(play: Play) {
        if (play.playerIndex === this.localPlayerIndex) {
            this._buttons.btnAttack.ui.text('Attack (x' + play.startAttackMultiplier + ')');
        }
    }

    _click(button : Button) {
        this.emit(button.name);
    }
}
