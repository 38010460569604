import { Play } from '../../Play';
import { ExtendedOutcome, OutcomeMetaStrategy } from './OutcomeMetaStrategy';

// Evalute the given functions and set the turnPoints
// The higher score will be the winner.
// Equal score evaluates as a win for both
// new HighScoreWinsMetaStrategy( {
//     winner : _ => { return 0 },
//     loser  : _ => { return -1 * (_.winner.turnPoints ?? 1) }
// })

export class HighScoreWinsMetaStrategy extends OutcomeMetaStrategy {

    protected getPlaysByOutcome(plays: Play[]): ExtendedOutcome {
        let playsByOutcome: any = { _winners: [], _losers: [], winner: null, loser: null };
        if (null == plays[0].turnPoints) throw new Error("HighScoreWinsMetaStrategy.getPlaysByOutcome() turnPoints is null")
        if (null == plays[1].turnPoints) throw new Error("HighScoreWinsMetaStrategy.getPlaysByOutcome() turnPoints is null")

        if (plays[0].turnPoints > plays[1].turnPoints) {
            playsByOutcome._winners[0] = playsByOutcome.winner = plays[0];
            playsByOutcome._losers[0] = playsByOutcome.loser = plays[1];
        } else if (plays[0].turnPoints < plays[1].turnPoints) {
            playsByOutcome._losers[0] = playsByOutcome.loser = plays[0];
            playsByOutcome._winners[0] = playsByOutcome.winner = plays[1];
        } else if (plays[0].turnPoints === plays[1].turnPoints) {
            playsByOutcome._winners = plays;
            playsByOutcome._losers = [];
            playsByOutcome.winner = playsByOutcome.loser = plays[0];
        }

        return playsByOutcome as ExtendedOutcome;
    }
}
