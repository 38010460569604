import { AttackPlay, AttackRangeStrategy } from './AttackRangeStrategy';
import { Coordinates } from '../Coordinates';

// Composite pattern for AttackRange strategy
// constructor parameters:
// [ {
// 		minWordLength: 		3, // word lengths when strategy applies
// 		maxWordLength: 		99,
// 		strategy: 	new OverlappingAttackRangeStrategy() // which strategy to apply
// 	}, ... ] );
export class CompositeAttackRangeStrategy extends AttackRangeStrategy {
	private composites: Array<{ minWordLength: number, maxWordLength: number, strategy: AttackRangeStrategy }>;

	constructor(
		cellSideCount: 4 | 6,
		composites: Array<{ minWordLength: number, maxWordLength: number, strategy: AttackRangeStrategy }>
	) {
		super(cellSideCount);
		this.composites = composites;
	}


    isAttackInRange(attackerPlay: AttackPlay, defenderCoordinates : Coordinates): boolean {
		if ( ! super.isAttackInRange(attackerPlay, defenderCoordinates) ) return false;

		let wordLength = attackerPlay.word.length
		if ( wordLength == 0 ) throw new Error('attackerPlay.word.length is 0')

		let isAttackInRange = false;
		this.composites.forEach( ({ minWordLength, maxWordLength, strategy }) => {
			if(minWordLength <= wordLength && wordLength <= maxWordLength) {
				if (strategy.isAttackInRange( attackerPlay, defenderCoordinates )) {
					isAttackInRange = true;
				}
			}
		})
		return isAttackInRange;
	}

}
