import { Play } from '../../Play';
import { GamePlayStrategy } from '../GamePlayStrategy';

interface Bonus {
    from: number;
    to: number;
    bonus: number;
}

export class WordLengthBonusStrategy implements GamePlayStrategy {
    private _bonuses: Bonus[];

    constructor(bonuses: Bonus[]) {
        this._bonuses = bonuses;
    }

    private _bonusForWordLength = (wordLength: number): number => {
        for (let i = this._bonuses.length - 1; i >= 0; --i) {
            if (this._bonuses[i].from <= wordLength && wordLength <= this._bonuses[i].to) {
                return this._bonuses[i].bonus;
            }
        }
        return 0;
    }

    execute ( plays: Play[] ) : boolean {
        plays.forEach(play => {
            if ( null == play.turnPoints ) throw new Error("WordLengthBonusStrategy.execute() turnPoints is null")
            if ( null == play.word || '' == play.word ) throw new Error("WordLengthBonusStrategy.execute() word is null")

            let bonus = this._bonusForWordLength(play.word.length);
            play.turnPoints += bonus
            play.pushPlayEvent({ wordIncrement: bonus })
        });
        return true; // return true if the function executes successfully
    }
}
