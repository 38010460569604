import { Play } from '../../Play';
import { BoardModel } from '../../ui/BoardModel';
import { GamePlayStrategy } from '../GamePlayStrategy';

export class EndGameFlagCountStrategy implements GamePlayStrategy {
    _flagCount = {};

    // cache of board flag counts
    getFlagCount( boardModel : BoardModel, gameId: string ) : number {
        let flagCount = this._flagCount[gameId];
        if ( flagCount != null) {
            return flagCount
        }
        this._flagCount[gameId] = 0
        boardModel.forEach(cell => {
            if (cell.hasFlag) this._flagCount[gameId]++
        })
        return this._flagCount[gameId]
    }

    execute(plays: Play[], args: { boardModel : BoardModel } ): boolean {
        let flagCount = this.getFlagCount(args.boardModel, plays[0].gameId)
        if (flagCount === 0) return true;
        
        let gameOver = false;
        plays.forEach(play => {
            if (play.endFlags.length >= flagCount) {
                gameOver = true;
            }
        })

        if ( gameOver ) {
            plays.forEach(play => {
                play.lost = (play.endFlags.length < flagCount)
                if ( play.lost ) {
                    play.endGameReason = `Game Over! Opponent collected all ${flagCount} flags`
                } else {
                    play.endGameReason = `Game Over! You collected all ${flagCount} flags!`
                }
            })
        }
        return true
    }
}
