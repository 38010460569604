import { log } from './lib/log';
import { EventEmitter } from './lib/EventEmitter'

import { Game, GameTemplate, Player } from './globals.d';

import { AuthController } from './auth/AuthController';
import { GameEvent } from './ui/GameEvent';
import { Play } from './Play';

interface ApiEndpoint {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    path: string;
}

export class RemoteProxy extends EventEmitter {
    private log: log.Logger = log.getLogger(this.constructor.name);;
    private gameId: string | null = null;
    private authController: AuthController;
    private _restBaseUrl: string;

    players: Record<string, Player> = {}; // details on all players we know about

    constructor(authController: AuthController, restBaseUrl: string) {
        super();
        this.log.setLevel(log.levels.INFO)

        this._restBaseUrl = restBaseUrl.replace(/\/+$/, '');
        this.authController = authController;
        this.authController.on(AuthController.AUTHENTICATED, () => this.registerPlayer());
    }

    /**
     * Get the player ID of the authenticated user.
     */
    public get playerId(): string | null {
        return this.authController.userId;
    }

    /**
     * Get the player object of the authenticated user.
     */
    public get player() : Player | undefined {
        return this.playerId ? this.players[this.playerId] : undefined
    }

    static API_ENDPOINTS: { [key: string]: ApiEndpoint } = {
        getApiServerStatus: { method: 'GET', path: '/status' }, // retrieve API server status (no auth required)
        getError: { method: 'GET', path: '/error/:code' }, // debug - generate HTTP error code

        getGamesForPlayer: { method: 'GET', path: '/games' }, // get list of games for (requesting) player
        postGame: { method: 'POST', path: '/games' }, // create new or join existing game

        getGame: { method: 'GET', path: '/games/:gameId' }, // get game details by ID
        putGame: { method: 'PUT', path: '/games/:gameId' }, // update game details
        postPlay: { method: 'POST', path: '/games/:gameId/plays' }, // create a new Play for a game

        postPlayer: { method: 'POST', path: '/players/:playerId' }, // update player details
    }

    /**
     * Invokes the API endpoint with the given name.
     * Results are passed to _receiveRemoteData().
     *
     * @param endpointName - The name of the API endpoint to invoke.
     * @param payload - The payload to send with the API request.
     * @param urlParams - The URL parameters to replace in the endpoint URL.
     * @returns a promise that resolves to the data received from the API.
     */
    _invokeApi(endpointName: string, payload: any = {}, urlParams: any = {}): Promise<any> {
        let url = this._restBaseUrl + RemoteProxy.API_ENDPOINTS[endpointName].path;
        Object.keys(urlParams).forEach(key => {
            const regex = new RegExp(`:${key}\\b`);
            url = url.replace(regex, urlParams[key]);
        });
        const method = RemoteProxy.API_ENDPOINTS[endpointName].method;
        const body = (method !== 'GET' ? JSON.stringify(payload) : undefined);

        this.log.debug('  ', method, url, body)
        return fetch(url, {
            method,
            headers: {
                'Authorization': 'Bearer ' + this.authController.idToken,
                'Content-type': 'application/json'
            },
            body,
        }
        ).then(async (response) => {
            if (!response.ok) {
                console.error('_invokeApi(', endpointName, ')', method, url, 'error: ', `HTTP error! status: ${response.status}`);
                if (response.status === 401) {
                    console.warn('API returned unauthorized; logging in again')
                    this.authController.login()
                    return Promise.reject('401 Unauthorised')
                } else if (response.status >= 400) {
                    let text: string = await response.text()
                    if (text) document.body.innerHTML = text
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                return response.json();
            }
        }
        ).then(data => this._receiveRemoteData(data)
        ).catch(error => {
            console.error('  ', endpointName, method, url, error.name, ': >', error.message, '<');

            if (error.message.includes("Failed to fetch")) {
                document.body.innerHTML = `
                    <h1>Connection Error</h1>
                    <p>Message: "${error.message}" status: "${error.status}" </p>
                    <p>Possible causes:</p>
                    <ul>
                        <li>Server is not available</li>
                        <li>Blocked by CORS</li>
                    </ul>
                `
                console.error('  ', endpointName, method, url, 'error: ', error.message);
            } else {
                throw new Error(error.message)
            }

            if (error.status === 401) {
                console.warn('API returned unauthorized; logging in again')
                this.authController.login()
            } else if (error.status >= 400 && error.responseText) {
                document.body.innerHTML = error.responseText;
            } else if (error.status === 0) {
                document.body.innerHTML = `
                <h1>Connection Error</h1>Could not connect to server ${this._restBaseUrl}. <br /> 
                message: "${error.message}" status: "${error.status}" <br />
                Is the API server running?`
                console.error('  ', endpointName, method, url, 'error: ', error.message);
            } else {
                throw new Error(error.message)
            }
        })
    }

    registerPlayer() {
        this.log.info(this.constructor.name, '.registerPlayer()');
        this._invokeApi('postPlayer', this.authController.playerInfo, { playerId: this.authController.userId })
    }

    /**
     * Join an existing game or create a new one.
     * @param gameTemplate - Optional game template definition, if specified will always create a new game.
     */
    createOrJoinGame(gameTemplate: GameTemplate | {} = {}) {
        this.log.info(this.constructor.name, '.createOrJoinGame(.)');

        this._invokeApi('postGame', {
            ...gameTemplate,
            playerList: [{
                playerId: this.authController.userId,
                playerName: this.authController.userName,
                playerPicture: this.authController.userPicture,
                isEnabled: true,
                createGameAllowed: true,
            }],
        })
    }

    /**
     * Get our list of games
     */
    getGamesForPlayer() {
        this.log.info(this.constructor.name, '.getGamesForPlayer()');
        return this._invokeApi('getGamesForPlayer', {})
    }

    /**
     * Loads a game.
     * @param gameId - The ID of the game to load.
     */
    loadGame(gameId: string): Promise<any> {
        this.log.info(this.constructor.name, '.loadGame( gameId: ', gameId, ')');
        return this._invokeApi('getGame', {}, { gameId })
    }

    /**
     * Called by Game object. Sends locally executed Play to server.
     * @param localPlay - The local play to execute.
     */
    executeLocalPlay(localPlay: Play): void {
        this.log.info(this.constructor.name, '.executeLocalPlay( localPlay: ', localPlay, ')');
        this._invokeApi('postPlay', localPlay, { gameId: this.gameId })
    }

    /**
     * Update game record
     *  - lastPlayedTurnIndexList
     * @param gameInfo - The new game info
     */
    updateGameTable(gameInfo: Game): void {
        this.log.info(this.constructor.name, '.updateGameTable(.)');
        this._invokeApi('putGame', 
            { 
                gameId: gameInfo.gameId,
                playerList: gameInfo.playerList,
                lastPlayedTurnIndexList: gameInfo.lastPlayedTurnIndexList,
            },
            { gameId: gameInfo.gameId });
    }

    /**
     * Loads a JSON file from the REST API.
     * @param path - The path to the JSON file on the API server
     * @returns a promise that resolves to the JSON data.
     */
    async loadJson(path: string): Promise<any> {
        this.log.info(this.constructor.name, '.loadJson( path: ', path, ')');
        const url = this._restBaseUrl + path;
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.authController.idToken,
            }
        })
            .then(response => response.json())
            .catch((error) => console.error('Error:', error));
    }

    /**
     * Loads an HTML file from the REST API.
     * @param path - The path to the HTML file on the API server
     * @returns a promise that resolves to the HTML data.
     */
    async loadHtml(path: string): Promise<any> {
        this.log.info(this.constructor.name, '.loadHtml( path: ', path, ')');
        const url = this._restBaseUrl + path;
        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.authController.idToken,
            }
        })
            .then(response => response.text())
            .catch((error) => console.error('Error:', error));
    }

    /**
     * Get the web app status (build info)
     */
    getWebAppStatus(webAppBaseUrl: string): Promise<any> {
        this.log.info(this.constructor.name, '.getWebAppStatus()')
        const requestUrl = webAppBaseUrl + '/version.json'
        return fetch(requestUrl)
            .then(response => response.json())
            .then(data => ({
                ...data,
                requestUrl
            }))
            .catch((error) => {
                console.error(requestUrl, 'Error:', error)
                alert(requestUrl + ' Error:' + error)
            })
    }

    /**
     * Get the API server status from the auth-free endpoint
     */
    getApiServerStatus(): Promise<any> {
        this.log.info(this.constructor.name, '.getApiServerStatus()');
        const endpointName = 'getApiServerStatus'
        const requestUrl = this._restBaseUrl + RemoteProxy.API_ENDPOINTS[endpointName].path
        return fetch(requestUrl)
            .then(response => response.json())
            .then(data => {
                if (!data) {
                    return {
                        error: "empty payload",
                        requestUrl,
                    };
                }
                return {
                    ...data.status,
                    requestUrl,
                };
            })            .catch((error) => {
                console.error(requestUrl, 'Error:', error)
                alert(requestUrl + ' Error:' + error)
            })
    }

    /**
     * Get the status of the webapp and API servers
     */
    getStatus(webAppBaseUrl: string): Promise<any> {
        this.log.info(this.constructor.name, '.getStatus()');
        return Promise.all(
            [
                this.getApiServerStatus(),
                this.getWebAppStatus(webAppBaseUrl),
            ]
        ).then(([apiServerStatus, webAppStatus]) => ({
            apiServerStatus,
            webAppStatus,
            authStatus: this.authController.playerInfo,
        }))
    }


    /**
     * Handles data received from the REST API.
     * @param data - The data bundle received from the API.
     */
    _receiveRemoteData(data: any): any {
        this.log.info(this.constructor.name, '._receiveRemoteData()');
        this.log.debug(this.constructor.name, "_receiveRemoteData( data=", data, ")");


        if (data == null) {
            throw new Error("_receiveRemoteData() - received null data result");
        }

        if (data.hasOwnProperty('errorMessage')) { // API Gateway-Lambda error
            throw new Error(`Remote reports error message: ${data.errorMessage}`);
        }

        // do this first in case subsequent events look up the player data
        if ('PlayerList' in data) this._playerListReceived(data.PlayerList);
        if ('GameList' in data) this._gameListReceived(data.GameList);

        // game specific data
        var gameId: string | null = null;
        if ('gameId' in data) {
            gameId = data.gameId;
        }

        if ('GameItem' in data) {
            gameId = data.GameItem.gameId;
            if (!gameId) throw new TypeError('_receiveRemoteData() - GameItem.gameId is required');
            this._gameItemReceived(data.GameItem);
            if ( data.GameItem.playListForPlayer && data.GameItem.playListForPlayer.length > 0){
                this._playListReceived(gameId, data.GameItem.playListForPlayer);
            }
        }

        if (gameId) {
            if (data.PlayList && data.PlayList.length > 0) {
                this._playListReceived(gameId, data.PlayList);
            }
        }

        return data
    }

    /**
     * Handles a game item received from remote.
     * @param gameItem - The game item received.
     */
    _gameItemReceived(gameItem: Game): void {
        this.log.info(this.constructor.name, '._gameItemReceived( { gameId:', gameItem.gameId, '...}');
        this.log.debug( `    GameItem: { lastPlayedTurnIndexList = ${
            gameItem.lastPlayedTurnIndexList
          }, plays[${
            gameItem.playListForPlayer?.[0]?.length
          },${
            gameItem.playListForPlayer?.[1]?.length
          }], ...}`)
  

        this.gameId = gameItem.gameId;

        this.emit(GameEvent.GAME_INFO, {
            ...gameItem,
            playListForPlayer: [] // we send these separately as Play class-objects
        })
    }

    /**
     * Handles a player list received from remote.
     * @param playerList - The list of players received.
     */
    _playerListReceived(playerList: Player[]): void {
        this.log.info(this.constructor.name, '._playerListReceived([])');
        playerList.forEach(player => {
            this.players[player.playerId] = player;
            if ( player.playerId === this.playerId) {
                this.emit(GameEvent.PLAYER_INFO, player)
            }
        })
    }

    /**
     * Handles a playListForPlayer list received from remote.
     * @param gameId - The ID of the game.
     * @param playListForPlayer - The 2D array of plays received
     */
    _playListReceived(gameId: string, playListForPlayer: any[][]): any {
        this.log.info(`${this.constructor.name}._playListReceived( gameId: ${gameId}, playListForPlayer[${
            playListForPlayer?.reduce(
                (prev, playlist) => (prev + '[' + (playlist?.length ?? '-') + ']'),
                '')
        }[${playListForPlayer?.[0]?.length}][${playListForPlayer?.[1]?.length}]])`);
        let result = {
            gameId,
            playList: playListForPlayer.flat(1).map((play => new Play(play))) // flatmap() fails for some reason
        }
        this.emit(GameEvent.PLAY_INFO, result)
        return result
    }

    /**
     * Handles a game list received from remote.
     * @param gameList - The list of games received.
     */
    _gameListReceived(gameList: Game[]): void {
        this.log.info(this.constructor.name, '._gameListReceived()');
        this.emit(GameEvent.GAME_LIST, gameList);
    }

    // server debug method - call /error endpoint to get HTTP error code
    _getError(code: number = 404) {
        this.log.info(this.constructor.name, '._getError()')
        this._invokeApi('getError', {}, { code })
    }
}
