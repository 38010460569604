import { log } from '../lib/log';

import { BoardView } from './BoardView';
import { CellModel } from './CellModel';
import { Coordinates } from '../Coordinates';
import { Direction } from '../Direction';
import { Play } from '../Play';

export class BoardModel {
	log: log.Logger = log.getLogger(this.constructor.name);

	_boardView: BoardView | null = null
	boardCells: CellModel[][]

	private _playerCells: CellModel[]

	_placedDirection: Direction
	private _placedCells: CellModel[]

	constructor() {
		this.log.setLevel(log.levels.SILENT);
		this.reset()
	}

	get isLoaded(): boolean {
		return this.boardCells.length > 0
	}

	reset() {
		this.boardCells = []
		this._playerCells = []
		this._placedDirection = Direction.ANY
		this._placedCells = []
	}

	loadBoard(textData: string) {
		this.log.info(this.constructor.name, '.loadBoard(.)');

		this.boardCells = CellModel.generateBoard(this, textData)

		// find the player cells
		this.forEach(cell => {
			if (cell != null && cell.playerIndex !== null) {
				this._playerCells[cell.playerIndex] = cell;
			}
		})
	}

	getHeight() {
		return this.boardCells.length
	}

	getWidth() {
		return (this.boardCells.length > 0 ? this.boardCells[0].length : 0)
	}

	// invoke callback( cell, coordinates ) - for every cell on the board
	forEach(callback) {
		this.boardCells.forEach((row) => {
			row.forEach((cellModel) => {
				callback(cellModel, cellModel.coordinates)
			})
		})
	}

	getCellAtCoordinates(coords: Coordinates): CellModel | null {
		if (!(coords instanceof Coordinates)) throw new Error(this.constructor.name + '.getCellAtCoordinates() - expected coordinates object');
		return this.getCellAt(coords.row, coords.col)
	}

	getCellAt(row: number, col: number): CellModel | null {
		if (row < 0 || row >= this.getHeight() ||
			col < 0 || col >= this.getWidth()) {
			return null;
		}
		return this.boardCells[row][col];
	}

	/**
	 * Return an array of cells in th order of eth
	 * @param coords 
	 * @returns 
	 */
	getCellsForCoordinates(coords: Coordinates[]): CellModel[] {
		return coords.map(coord => {
			let cell = this.boardCells[coord.row][coord.col]
			if (cell) {
				return cell
			} else {
				throw new Error(`Invalid coordinates: (${coord.row}, ${coord.col})`);
			}
		})
	}

	/**
	 * From the starting cell, find the word candidate cells including placed and static cells.
	 * @param startCell starting cell, not included in results
	 * @param direction ordinal direction to search
	 * @returns array of cells that are part of the word candidate, [] if no word candidate
	 */
	getWordCandidateCells(playerIndex: number): CellModel[] {
		this.log.info(this.constructor.name, '.getWordCandidateCells()');

		let direction = this._placedDirection
		if (direction == Direction.ANY) {
			return []
		} else {
			let cells: CellModel[] = [];
			var endOfCandidates = false;

			let playerCell = this.getPlayerCell(playerIndex);
			if (!playerCell) throw new Error('getWordCandidateCells() - invalid player index')

			playerCell.getAllCellsInDirection(direction).forEach(cell => {
				if ((cell.isPlaced || cell.isStatic) && !endOfCandidates) {
					cells.push(cell);
				} else {
					endOfCandidates = true;
				}
			})

			return cells;
		}
	}

	getPlayedWord(playerIndex: number): string {
		return this.getWordCandidateCells(playerIndex).reduce(
			(word, cell) => (word + cell.currentLetter), '')
	}

	getPlayerCell(playerIndex: number): CellModel {
		return this._playerCells[playerIndex];
	}

	/**
	 * Sets the player's cell on the board based on the given play.
	 *
	 * @param play - The play object containing the player's index and start position.
	 * @return Returns true if the player's coordinates were successfully set, false if the player is already at the specified coordinates.
	 */
	setPlayerCell(play: Play): boolean {
		this.log.info('BoardModel.setPlayerCell(.)');
		return this.setPlayerCoordinates(play.playerIndex, play.startCoords)
	}

	/**
	 * Sets the coordinates of a player on the board.
	 *
	 * @param playerIndex - The index of the player.
	 * @param coordinates - The coordinates of the player.
	 * @return Returns true if the player's coordinates were successfully set, false if the player is already at the specified coordinates.
	 * @throws Throws an error if the coordinates are invalid.
	 */
	setPlayerCoordinates(playerIndex: number, coordinates: Coordinates): boolean {
		let playerCell = this.getCellAtCoordinates(coordinates);
		if (null == playerCell) throw new Error('setPlayerCell() - invalid coordinates')
		if (playerCell.playerIndex === playerIndex) return false

		this._playerCells[playerIndex].playerIndex = null // remove from old cell
		this._playerCells[playerIndex] = playerCell // index the new cell
		this._playerCells[playerIndex].playerIndex = playerIndex // update the new cell
		return true
	}

	getPlaceableCells(): CellModel[] {
		let cells: CellModel[] = []
		this.forEach(cell => {
			if (cell.isPlaceable) cells.push(cell)
		})
		return cells
	}

	resetPlaceableCells() {
		this.forEach(cell => {
			if (cell.isPlaceable) cell.setPlaceable(Direction.ANY)
		})
	}

	placeLetter(cell: CellModel, letter: string) {
		if (!cell.isPlaceable) throw new Error("Cannot place letter on unplaceable cell");
		this._placedDirection = cell.placeLetter(letter)
		if (this._placedDirection == Direction.ANY) throw new Error('Invalid direction')
		this._placedCells.push(cell)
	}

	resetPlacedDirection() {
		this._placedDirection = Direction.ANY
	}

	get placedDirection(): Direction {
		return this._placedDirection;
	}

	// list of cells that are placed
	get placedCells(): CellModel[] {
		return this._placedCells;
	}

	get placedRange(): Coordinates[] {
		return this._placedCells.map(cell => cell.coordinates)
	}

	resetPlacedCells() {
		this._placedCells.forEach(cell => cell.unplaceLetter())
		this.resetPlacedDirection()
		this._placedCells = [];
	}
}
