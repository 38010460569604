import { BoardModel } from '../../ui/BoardModel';
import { Coordinates } from '../../Coordinates';
import { Play } from '../../Play';

import { GamePlayStrategy } from '../GamePlayStrategy';

export class WordScoreStrategy implements GamePlayStrategy {
    private scoreForLetter: (letter: string) => number;

    constructor(scoreForLetter: (letter: string) => number) {
        this.scoreForLetter = scoreForLetter
    }

    execute(plays: Play[], args: { boardModel : BoardModel } ): boolean {
        plays.forEach(play => {
            let score = 0
            let wordBonus = 1
            play.playedRange.forEach(
                (coords : Coordinates, index : number) => {
                    if ( index === 0 ) return // skip playerCell
                    let cell = args.boardModel.getCellAtCoordinates(coords)
                    if ( !cell ) throw new Error('getPlayedScore() - invalid cell')
                    let letter = play.word[index-1]
                    let letterScore = this.scoreForLetter(letter)
                    score += letterScore * cell.letterBonus
                    play.pushPlayEvent({ coords, letter, scoreIncrement: letterScore })
                    play.pushPlayEvent({ coords, cellScoreMultiplier: cell.letterBonus })
                    wordBonus *= cell.wordBonus
                    play.pushPlayEvent({ wordScoreMultiplier: wordBonus })
                }
            )
            play.wordPoints = wordBonus * score;
        })
        return true;
    }

}
