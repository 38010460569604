import { log } from '../lib/log';

export class LettersModel {
    private _letterCount: number;
    private _letters: string[];
    private _isPlaced: boolean[];
    private _selectedIndex: number | null;
    private log: log.Logger = log.getLogger(this.constructor.name);
    readonly scoreForLetter: Function;

    constructor(scoreForLetter: any) {
        this.log.setLevel(log.levels.INFO)

        this.scoreForLetter = scoreForLetter;

        this._letterCount = 0;
        this._letters = [];
        this._isPlaced = [];
        this._selectedIndex = null;
    }

    getLetterCount(): number {
        return this._letterCount;
    }

    setLetterCount(letterCount: number): void {
        this.log.info('LettersModel.setLetterCount(', letterCount, ')');
        this._letterCount = letterCount
        this._isPlaced = Array.from(Array(this._letterCount)).map(() => false)
        this._selectedIndex = null
    }

    getLetters(): string[] {
        return this._letters;
    }

    resetLetters(letterValues: string): void {
        this.log.info('LettersModel.resetLetters(', letterValues, ')');
        this._letters = letterValues.split('');
        this.setLetterCount(this._letters.length);
    }

    getScore(index: number): string {
        return this.scoreForLetter(this._letters[index]);
    }

    isPlaced(index: number): boolean {
        return this._isPlaced[index];
    }

    setPlaced(index: number, value: boolean): void {
        this._isPlaced[index] = value;
    }

    placeByLetter(letterToPlace: string) {
        let placed = false
		this._letters.forEach( (letter, index) => {
            if ( letter === letterToPlace && !this.isPlaced(index) && ! placed ) {
                this.setPlaced(index, true)
                placed = true
            }
        })
	}

    getIndexOfUnplaced(letterToPlace: string): number | undefined {
        let unplacedIndex : Number | undefined = undefined
		this._letters.forEach( (letter, index) => {
            if ( letter === letterToPlace && !this.isPlaced(index) && ! index ) {
                this.setPlaced(index, true)
                unplacedIndex = index
            }
        })
        return unplacedIndex
	}


    resetPlacedCells(): void {
        for (let i = this.getLetterCount() - 1; i >= 0; --i) {
            this.setPlaced(i, false);
        }
    }

    select(index: number): void {
        this.log.info('LettersModel.select(' + index + ')');
        this._selectedIndex = index;
    }

    unselect(): void {
        this._selectedIndex = null;
    }

    getSelectedIndex(): number | null {
        return this._selectedIndex;
    }

    getSelectedLetter(): string | null {
        return this._selectedIndex !== null ? this._letters[this._selectedIndex] : null;
    }

    toString(): string {
        let letterString = this._letters.join('')
        return `letters: ${letterString}\n` +
            `https://www.thewordfinder.com/anagram-solver/?dict=sowpods&letters=${letterString.toLowerCase()}`
    }
}
