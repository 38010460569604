import { AttackPlay, AttackRangeStrategy } from './AttackRangeStrategy';
import { Coordinates } from '../Coordinates'

// attack is in-range if it overlaps the opponent cell
export class OverlappingAttackRangeStrategy extends AttackRangeStrategy {
    isAttackInRange(attackerPlay: AttackPlay, defenderCoordinates : Coordinates): boolean {
		this.log.info(this.constructor.name, '.isAttackInRange(..)');
		if ( ! super.isAttackInRange(attackerPlay, defenderCoordinates) ) return false;

		let isInRange = false;
		attackerPlay.playedRange.forEach( coord => {
			if (coord.equals(defenderCoordinates)) {
				isInRange = true;
			}
		})
		return isInRange
	}
}
