import { Play } from '../../Play';
import { GamePlayStrategy } from '../GamePlayStrategy';

export class EndGameMaxTurnsStrategy implements GamePlayStrategy {
    private _maxTurns: number;

    constructor(maxTurns: number) {
        this._maxTurns = maxTurns;
    }

    execute(plays: Play[], _args: any): boolean {
        if (plays[0].turnIndex < (this._maxTurns - 1)) {
            return true; // not yet
        }
        let highScore = plays.reduce((prev, play) => Math.max(prev, play.endTurnScore || 0), Number.MIN_SAFE_INTEGER);
        plays.forEach(play => {
            if ( null == play.endTurnScore ) throw new Error("EndGameMaxTurnsStrategy.execute() endTurnScore is null")
            play.lost = ( play.endTurnScore < highScore )
            if ( play.lost ) {
                play.endGameReason = "Endo of game. Your opponent beat you";
            } else {
                play.endGameReason = "Game over! You beat your opponent";
            }
        })
        return true;
    }
}
