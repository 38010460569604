import { log } from '../lib/log';

import { AudioProxy } from '../AudioProxy';
import { BoardModel } from './BoardModel';
import { BoardView } from './BoardView';
import { ButtonsView } from './ButtonsView';
import { CellView, CELL_CLICKED } from './CellView';
import { CellModel } from './CellModel';
import { Coordinates } from '../Coordinates';
import { Direction } from '../Direction';
import { EventEmitter } from '../lib/EventEmitter';
import { GameController, GameControllerEvent } from './GameController';
import { LettersModel } from './LettersModel';
import { LettersView } from './LettersView';
import { Play } from '../Play';


export class BoardController {
	private log: log.Logger = log.getLogger(this.constructor.name)

	private _boardView: BoardView

	_boardModel: BoardModel
	_lettersModel: LettersModel
	_lettersView: LettersView
	_buttonsView: ButtonsView
	_gameController: GameController

	_audio = new AudioProxy()

	private _cellSideCount: 4 | 6 = 4 // the shape of cells

	constructor(args: {
		boardModel: BoardModel,
		boardView: BoardView,
		lettersModel: LettersModel,
		lettersView: LettersView,
		buttonsView: ButtonsView,
		gameController: GameController,
		playEmitter: EventEmitter,
	}) {
		this.log.setLevel(log.levels.DEBUG);

		this._boardModel = args.boardModel;
		this._boardView = args.boardView;
		this._lettersModel = args.lettersModel
		this._lettersView = args.lettersView
		this._buttonsView = args.buttonsView
		this._gameController = args.gameController;

		args.playEmitter.on(GameControllerEvent.NEW_TURN,
			(play: Play) => this.handleNewTurn(play));
		args.playEmitter.on(GameControllerEvent.END_TURN,
			(play: Play) => this.handleEndTurn(play))
		this._boardView.on(CELL_CLICKED,
			(cellView: CellView) => this.handleCellClicked(cellView))
	}

	loadBoard(boardData: string) {
		log.info(this.constructor.name, 'loadBoard(.)')
		this._boardModel.loadBoard(boardData)
		this._boardView.loadBoard()
	}

	set cellSideCount(cellSideCount: 4 | 6) {
		this._cellSideCount = cellSideCount;
		this._boardView.cellSideCount = cellSideCount;
	}

	/**
	 * Handles the received play for starting a turn.
	 *
	 * @param play - The play object representing the player's move.
	 */
	handleNewTurn(play: Play) {
		log.debug(this.constructor.name, 'handleNewTurn(.)')
	}

	/**
	 * Show the play on the board
	 *
	 * @param play - The play object representing the range played.
	 */
	handleEndTurn(play: Play) {
		log.debug(this.constructor.name, 'handleEndTurn(.)')
	}

	/**
	 * Handles the cell clicked event.
	 *
	 * @param cellView - The cell view object representing the clicked cell.
	 */
	handleCellClicked(cellView: CellView) {
		log.debug(this.constructor.name, 'handleCellClicked(.)');
		let selectedIndex = this._lettersModel.getSelectedIndex();
		let selectedLetter = this._lettersModel.getSelectedLetter();
		if (selectedIndex == null || selectedLetter == null) { // no letter selected
			this._lettersView.flashView(); // nudge the user
			return;
		} else {
			this.placeLetterOnBoard(cellView.model, selectedLetter, selectedIndex);
		}
	}


	/**
	 * Place the selected letter on the board.
	 *
	 * @param cellModel - The cell model representing the selected cell.
	 * @return true if the letter was placed, false if the cell was not placeable.
	 */
	placeLetterOnBoard(cellModel: CellModel, selectedLetter: string, selectedIndex: number) {
		this.log.info(this.constructor.name, '.placeLetterOnBoard(., selectedLetter=', selectedLetter, ', selectedIndex=', selectedIndex, ')');

		if (selectedLetter.length !== 1) throw new Error(`placeLetterOnBoard received invalid selectedLetter ${selectedLetter}`)

		if (!cellModel.isPlaceable) { // user clicked unplaceable cell
			this._boardView.flashBoard()
			return false
		}

		//
		// valid placement
		//
		this._audio.placeTile();

		this._boardModel.placeLetter(cellModel, selectedLetter);
		this.resetPlaceableCells();
		this.markPlaceableCells(this._gameController._localPlayerIndex)

		this._lettersModel.setPlaced(selectedIndex, true);
		this._lettersModel.unselect();
		this._lettersView.updateSelection();
		this._lettersView.updatePlaced();

		this._buttonsView.enable('btnMove', true);
		this._buttonsView.enable('btnReset', true);

		// hint the direction of play
		this._boardView.flashPlaceableRow(cellModel, this._boardModel.placedDirection);

		// if we can attack
		if (this._gameController._getCurrentPlayForPlayer().startAttackMultiplier > 0 && this.isAttackInRange()) {
			this._buttonsView.enable('btnAttack', true);

			this.highlightAttackableWhere(
				(_cell: any, coords: Coordinates) => this.areCoordinatesinAttackRange(coords)
			);
		} else {
			this._buttonsView.enable('btnAttack', false)
		}
		return true
	}

	// @TODO: refactor this - store intermediate variables in current Play and pass that object
	areCoordinatesinAttackRange(target: Coordinates): boolean {
		log.debug(this.constructor.name, '.areCoordinatesinAttackRange(.)')
		let endOfWordCell = this.getEndOfWordCell(this._gameController._localPlayerIndex);

		let wordCandidateCells = this._boardModel.getWordCandidateCells(this._gameController._localPlayerIndex)
		return (endOfWordCell != null) && this._gameController._gameRuleLogic.attackRangeStrategy.isAttackInRange(
			{ // draft of the Play object, compatbile with AttackPlay
				startCoords: this._boardModel.getPlayerCell(this._gameController._localPlayerIndex).coordinates,
				endWordCoords: endOfWordCell.coordinates,
				word: this._boardModel.getPlayedWord(this._gameController._localPlayerIndex),
				playedRange: wordCandidateCells.map(cell => cell.coordinates),
				direction: this._boardModel.placedDirection,
			},
			target,
		)
	}

	/**
	 * Use the attackRangeStrategy to determine whether other player(s) are in range are in range
	 * @returns if any other player is in range
	 */
	isAttackInRange(): boolean {
		log.debug(this.constructor.name, '.isAttackInRange(.)')
		if (!this._gameController._gameInfo) throw new Error("gameInfo not set")
		for (var opponentIndex = this._gameController._gameInfo.playerList.length - 1; opponentIndex >= 0; opponentIndex--) { // check the other players
			if (opponentIndex != this._gameController._localPlayerIndex && // can't attack ourself
				this.areCoordinatesinAttackRange(this._boardModel.getPlayerCell(opponentIndex).coordinates)
			) {
				return true;
			}
		}
		return false
	}


	/**
	 * Determine the placeable cells for the specified player index.
	 * Update the cells to be placeable and return an array of placeable cells.
	 *
	 * @param playerIndex - The index of the player.
	 * @returns An array of placeable cells.
	 */
	markPlaceableCells(playerIndex: number): CellModel[] {
		this.log.info(this.constructor.name, '.markPlaceableCells(playerIndex=', playerIndex, ')');
		let placeableCells: CellModel[] = [];

		let playerCell = this._boardModel.getPlayerCell(playerIndex);

		let placedCells = this._boardModel.placedCells;
		if (placedCells.length === 0) {
			this.log.info(this.constructor.name, '.markPlaceableCells() - all directions');
			// no placed cells - all directions
			Direction.allOrdinalDirections(this._cellSideCount).forEach(direction => {
				let cell = this._getNextPlaceable(playerCell, direction);
				if (cell) {
					cell.setPlaceable(direction);
					placeableCells.push(cell);
				}
			})
		} else {
			let direction = placedCells[0].direction
			this.log.info(this.constructor.name, '.markPlaceableCells() - ', direction.toString());
			let cell = this._getNextPlaceable(
				placedCells[placedCells.length - 1], direction
			)
			if (cell) {
				cell.setPlaceable(direction);
				placeableCells.push(cell)
			}
		}
		return placeableCells;
	}

	getPlaceableCells() {
		this.log.debug(this.constructor.name, '.getPlaceableCells()');
		return this._boardModel.getPlaceableCells()
	}

	/**
	 * Get the next placeable cell in the specified direction from the given cell.
	 *
	 * @param fromCell - The starting cell.
	 * @param direction - The direction to search for the next placeable cell 
	 * @returns The next placeable cell, or null if no more placeable cells in the specified direction.
	 */
	private _getNextPlaceable(
		fromCell: CellModel,
		direction: Direction
	): CellModel | null {
		this.log.info(this.constructor.name, '._getNextPlaceable(., direction=' + direction + ')');

		let placeableCell: CellModel | null = fromCell;
		do {
			placeableCell = placeableCell.getAdjacentCell(direction);
			if (placeableCell?.isBlock) return null // stop at a block
		} while (
			placeableCell && // stop when no more cells
			(placeableCell.isPlaced || placeableCell.isStatic) // iterate over placed and static
		)
		return placeableCell
	}

	/**
	 * Highlights the attackable cells on the board based on the provided callback function.
	 *
	 * @param callback - The callback function that determines if a cell is attackable or not.
	 *                 The callback function should accept two parameters: cell and coords.
	 *                 - cell: The cell object representing a specific cell on the board.
	 *                 - coords: The coordinates of the cell on the board.
	 *                 The callback function should return a boolean value indicating if the cell is attackable or not.
	 */
	highlightAttackableWhere(callback: (cell: CellModel, coords: Coordinates) => boolean): void {
		log.debug(this.constructor.name, '.highlightAttackableWhere(.)')
		var board = this._boardModel
		this._boardModel.forEach((cell: CellModel, coords: Coordinates) => {
			cell.isAttackable = callback(cell, coords)
		});
	}

	unhighlightAttackable() {
		log.debug(this.constructor.name, '.unhighlightAttackable(.)')
		this.highlightAttackableWhere(() => (false))
	}

	/**
	 * Remove the word from the board
	 */
	resetWord() {
		this.log.info(this.constructor.name, '.resetWord(.)');
		this._boardModel.resetPlacedCells();
		this._boardModel.resetPlacedDirection()
		this.resetPlaceableCells();
		this.unhighlightAttackable();
	}

	resetPlaceableCells() {
		this._boardModel.resetPlaceableCells();
	}

	/**
	 * Returns the end cell of the word for the specified player index.
	 *
	 * @param playerIndex - The index of the player.
	 * @returns The end cell of the word, or null if not found.
	 */
	getEndOfWordCell(playerIndex: number): CellModel | null {
		log.debug(this.constructor.name, '.getEndOfWordCell(playerIndex=', playerIndex, ')');
		let cells = this._boardModel.getWordCandidateCells(playerIndex);
		return cells[cells.length - 1];
	}
}

