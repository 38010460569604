// enforce minimum maximum total score
import { log } from '../../lib/log';
import { Play } from '../../Play';
import { GamePlayStrategy } from '../GamePlayStrategy';

export class MinMaxEndTurnStrategy implements GamePlayStrategy {
    private log = log.getLogger(this.constructor.name);
    private _minValue: number;
    private _maxValue: number;

    constructor(minValue: number, maxValue: number) {
        this.log.setLevel(log.levels.SILENT)
        this._minValue = minValue;
        this._maxValue = maxValue;
    }

    execute(plays: Play[], _args: any): boolean {
        this.log.info(this.constructor.name, '.execute()');

        plays.forEach(play => {
            if (null == play.endTurnScore) throw new Error("MinMaxEndTurnStrategy.execute() endTurnScore is null")

            if (play.endTurnScore > this._maxValue) {
                play.endTurnScore = this._maxValue;
                play.pushPlayEvent({ maxScore: this._maxValue })
            } else if (play.endTurnScore < this._minValue) {
                play.endTurnScore = this._minValue;
                play.pushPlayEvent({ minScore: this._minValue })
            }
        })
        return true
    }
}
