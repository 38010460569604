import { log } from './lib/log';

import { Coordinates } from './Coordinates';
import { Game } from './globals';
import { PlayEvent } from './PlayEvent';
import { PlayType } from './PlayType';
import { Direction } from './Direction';

export class Play {
	log: log.Logger;

	gameId: string;
	turnIndex: number;
	playerIndex: number;

	startAttackMultiplier: number;
	startFlags: Array<Coordinates>;
	startCoords: Coordinates;
	startTurnScore: any;

	direction: Direction = Direction.ANY
	endWordCoords: Coordinates | null;
	isWordReversed: boolean = false; // whether we had to reverse the word to find it in the dictionary
	playType: PlayType | null;
	word: string | ''; // original word in order of letters played (and statics)
	
	turnPoints: number | null;
	wordPoints: number | null;
	
	endAttackMultiplier: number | null;
	endFlags: Array<Coordinates>;
	endCoords: Coordinates | null;
	endTurnScore: number | null;
	
	cellPlayEvents: PlayEvent[] = []; // events triggered by a cell
	endPlayEvents: PlayEvent[] = []; // events for the end of the play (no coordinates)

	endGameReason: string;
	lost: boolean;

	constructor(parameters: any = {}) {
		this.log = log.getLogger(this.constructor.name);
		this.log.setLevel(log.levels.SILENT)

		// Initialize properties with default values in the declared order
		this.gameId = parameters.gameId;
		this.turnIndex = parameters.turnIndex;
		this.playerIndex = parameters.playerIndex;

		this.direction = Direction.toDirection(parameters.direction)
		this.startAttackMultiplier = parameters.startAttackMultiplier;
		this.startFlags = (null == parameters.startFlags ? [] : parameters.startFlags.map((c: any) => Coordinates.toCoordinates(c)));
		this.startCoords = Coordinates.toCoordinates(parameters.startCoords);
		this.startTurnScore = parameters.startTurnScore;

		this.endWordCoords = Coordinates.toOptionalCoordinates(parameters.endWordCoords)
		this.isWordReversed = parameters.isWordReversed || false;
		this.playType = PlayType.toPlayType(parameters.playType);
		this.turnPoints = (null == parameters.turnPoints ? null : parameters.turnPoints);
		this.word = parameters.word || '';
		this.wordPoints = (null == parameters.wordPoints ? null : parameters.wordPoints);

		this.endAttackMultiplier = (null == parameters.endAttackMultiplier ? null : parameters.endAttackMultiplier);
		this.endFlags = (null == parameters.endFlags ? [] : parameters.endFlags.map((c: any) => Coordinates.toCoordinates(c)));
		this.endCoords = Coordinates.toOptionalCoordinates(parameters.endCoords)
		this.endTurnScore = (null == parameters.endTurnScore ? null : parameters.endTurnScore);

		this.lost = parameters.lost || false;
		this.endGameReason = parameters.endGameReason || '';
	}

	static createFirstPlay(gameInfo: Game, playerIndex: number, startCoords: Coordinates): Play {
		const { gameId, rules: { startGameScore } } = gameInfo;
		const play = new Play({
			gameId: gameId,
			turnIndex: 0,
			playerIndex: playerIndex,

			startAttackMultiplier: 0,
			startFlags: [],
			startCoords: startCoords,
			startTurnScore: startGameScore,

			isWordReversed: false,
			playType: null,
			turnPoints: null,
			word: '',
			wordPoints: null,
			
			endAttackMultiplier: null,
			endFlags: [],
			endCoords: null,
			endTurnScore: null,
			endWordCoords: null,

			lost: false,
			endGameReason: ''
		});

		return play;
	}

	createNextTurnPlay() {
		this.assertComplete();

		return new Play({
			gameId: this.gameId,
			turnIndex: this.turnIndex + 1, // next turnIndex
			playerIndex: this.playerIndex,

			startAttackMultiplier: this.endAttackMultiplier,
			startFlags: [ ...this.endFlags ], // endFlags -> startFlags
			startCoords: this.endCoords, // endCoords -> startCoords
			startTurnScore: this.endTurnScore, // endTurnScore -> startTurnScore

			direction: Direction.ANY,
			isWordReversed: false,
			playType: null,
			turnPoints: null,
			word: '',
			wordPoints: null,
			
			endAttackMultiplier: null,
			endFlags: [],
			endCoords: null,
			endTurnScore: null,
			endWordCoords: null,

			lost: this.lost,
			endGameReason: this.endGameReason,
		});
	}

	private isPlayedReason() : string {
		if (null == this.playType) return "Play.playType is null"
		if (null == this.word || this.word.length <= 0) return "Play.word is null"
		if (null == this.endWordCoords) return "Play.endWordCoords is null"
		if (this.direction === Direction.ANY) return "Play.direction unset"
		return ''
	}

	/**
	 * Check the play has been locally executed - a word has been placed and playType set
	 */
	get isPlayed() : boolean {
		return (this.isPlayedReason() === '')
	}
	
	assertPlayed() {
		let reason = this.isPlayedReason()
		if (reason !== '') throw new Error('Play.assertPlayed() '+ reason);
	}

	/**
	 * Play isPlayed() and updated based on other plays in the turn
	 */
	assertComplete() {
		let reason = this.isPlayedReason()
		if (reason !== '') throw new Error('Play.assertComplete() '+ reason);
		if (this.turnPoints == null) throw new Error('Play.assertComplete() turnPoints is null');

		if (!this.endCoords) throw new Error('Play.assertComplete() error: endCoords is null');
		if (this.endTurnScore == null) throw new Error('Play.assertComplete() error: endTurnScore is null');
		if (this.endAttackMultiplier == null) throw new Error('Play.assertComplete() error: endAttackMultiplier is null');
	}

	addFlag(coordinates: Coordinates) {
		if (!this.endFlags.some(coord => coord.equals(coordinates))) {
			this.endFlags.push(coordinates);
		}
	}

	removeFlag(coordinates: Coordinates) {
		this.endFlags = this.endFlags.filter(coord => !coord.equals(coordinates));
	}

	toJSON(): any {
		return {
			gameId: this.gameId,
			turnIndex: this.turnIndex,
			playerIndex: this.playerIndex,

			startAttackMultiplier: this.startAttackMultiplier,
			startFlags: this.startFlags.map( flagCoord => flagCoord.toJSON()),
			startTurnScore: this.startTurnScore,
			startCoords: this.startCoords.toJSON(),
			
			direction: this.direction.toString(),
			endWordCoords: this.endWordCoords?.toJSON(),
			isWordReversed: this.isWordReversed,
			playType: this.playType,
			word: this.word,
		};
	}

	id(): string {
		return this.gameId + '-' + this.turnIndex + '-' + this.playerIndex;
	}

	equals(other: Play) {
		return JSON.stringify(this) === JSON.stringify(other);
	}

	// Compare this play to another play in terms of power in the game
	// +1 if this beats     other
	//  0 if this ties with other
	// -1 if this loses to  other
	cmp(other: Play) {
		this.assertPlayed()
		other.assertPlayed()

		if (this.playType != other.playType) { // attack vs move			
			return (this.playType === PlayType.ATTACK ? 1 : -1); // attack beats move

		} else if ((this.turnPoints ?? 0) > (other.turnPoints ?? 0)) { // higher turnPoints wins
			return 1;

		} else if ((other.turnPoints ?? 0) > (this.turnPoints ?? 0)) {
			return -1;

		} else if (this.word.length > other.word.length) { // longer word wins
			return 1;

		} else if (other.word.length > this.word.length) {
			return -1;

		} else if (this.word > other.word) { // alphabetical later word wins
			return 1;

		} else if (other.word > this.word) {
			return -1;

		} else if ( this.playerIndex === other.playerIndex ) {
			return 0;

		// "coin toss" based on turnIndex
		} else if ( this.turnIndex % 2 == this.playerIndex ) {
			return 1;
		} else if ( this.turnIndex % 2 != this.playerIndex ) {
			return -1;
		} else {
			throw new Error('cmp() error: invalid comparison');
		}
	}

	get playedRange() : Coordinates[] {
		this.assertPlayed()
		if (this.endWordCoords == null) throw new Error('Play.playedRange() error: no endWordCoords')
		return this.startCoords.coordinateRangeTo(this.endWordCoords, this.direction)
	}

	/**
	 * Summary of this play - type, word, and turn points
	 */
	toPlayDescription() : string {
		this.assertComplete();
		return '' +
			this.playType?.toString() +
			(this.playType === PlayType.ATTACK ? ' (x' + this.startAttackMultiplier + ')' : '') +
			': ' +
			( this.isWordReversed ? this.getReversedWord() : this.word )+ 
			' ' +
			((this.turnPoints ?? 0) >= 0 ? '+' : '') +
			this.turnPoints;
	}

	getReversedWord(str : string = this.word) : string {
		return str.split('').reverse().join('')
	}

	/**
	 * Add a play event - things that update the score
	 * @param event 
	 * @returns 
	 */
	pushPlayEvent(event: any) {
		if (event.cellScoreMultiplier === 1) return // no-op
		if (event.wordScoreMultiplier === 1) return // no-op
		if (event.coords) {
			this.cellPlayEvents.push(new PlayEvent(event));
		} else {
			this.endPlayEvents.push(new PlayEvent(event));
		}
	}

	/**
	 * Detailed description of the score calculation from PlayEvents
	 */
	get turnPointDescription() : string {
		let desc = ''
		this.cellPlayEvents.forEach(playEvent => {
			let eventDesc = playEvent.description
			if (eventDesc !== '') desc += eventDesc + ' ';
		});
		if (this.endPlayEvents.length > 0) {
			if (desc.length > 0) desc = `(${desc}) `
			this.endPlayEvents.forEach(playEvent => {
				let eventDesc = playEvent.description
				if (eventDesc !== '') {
					if (eventDesc !== '') desc += eventDesc + ' ';
				}
			})
		}
		if (this.turnPoints) {
			if (desc.length > 0) desc = `(${desc.trim()}) = `
			desc += `${this.turnPoints} {turn points}`
		}
		if (this.endTurnScore) {
			desc += ` + ${this.startTurnScore} {start score} = ${this.endTurnScore} {end score}`}
		return  desc.trim()
	}
}
