import { log } from '../lib/log';
import { EventEmitter } from '../lib/EventEmitter';

import { GameEvent } from './GameEvent';
import { GameController } from './GameController';
import { GameListView } from './GameListView';
import { RemoteProxy } from '../RemoteProxy';

import { Game, GameAnnotated, GameTemplate, Player } from '../globals';

export class GameListController extends EventEmitter {
    // GameList events
    static PROMPT_NEW_GAME = 'PROMPT_NEW_GAME'; // prompt user for new game details
    static JOIN_GAME = 'JOIN_GAME';
    static GAME_SELECTED = 'GAME_SELECTED';

    private log = log.getLogger(this.constructor.name);

    private _gameListView: GameListView;
    private _remoteProxy: RemoteProxy | null = null;
    private _createGameAllowed: boolean = false;

    constructor(gameListView: GameListView) {
        super();
        this.log.setLevel(log.levels.INFO)

        setTimeout(() => this._poll(), 5 /*seconds*/ * 1000);

        this._gameListView = gameListView;

        this._gameListView.on(GameListController.JOIN_GAME, () => this.handleJoinGame())
        this._gameListView.on(GameListController.PROMPT_NEW_GAME, () => this.handlePromptNewGame())
        this._gameListView.on(GameListController.GAME_SELECTED, gameId => this.handleGameSelected(gameId))
    }

    static UNKNOWN_PLAYER: Player = {
        playerId: "??",
        playerName: "Opponent",
        playerPicture: "/images/avatar-unknown.png",
        isEnabled: false,
        createGameAllowed: false,
        gameIdList: [],
    }

    setRemote(remoteProxy: RemoteProxy) {
        this._remoteProxy = remoteProxy;
        this._remoteProxy.on(GameEvent.GAME_LIST, gameList => this._populateGameList(gameList))
        this._remoteProxy.on(GameEvent.PLAYER_INFO, player => this.handlePlayerInfo(player))
    }

    handlePlayerInfo(player: Player): void {
        this._createGameAllowed = player.createGameAllowed;
    }

    setVisibility(isVisible: boolean) {
        this.log.info(this.constructor.name, `.setVisibility( ${isVisible} )`);
        this._gameListView.setVisibility(isVisible);
    }

    getGames() {
        this.log.info(this.constructor.name, '.getGames()');
        this._remoteProxy?.getGamesForPlayer()
            .catch(err => {
                this.log.error(`GameListController.getGames - remote.getGamesForPlayer() error: ${err}`)
            })

    }

    _populateGameList(gameList: Array<Game>) {
        this.log.info(this.constructor.name, '._populateGameList()');

        this._gameListView.clearGames();
        if (this._createGameAllowed) {
            this._gameListView.appendJoinGameControl( "Join Game" );
            this._gameListView.appendNewGameControl();
        } else {
            this._gameListView.appendJoinGameControl( "New Game");
        }

        gameList
            .map(game => this._toGameAnnotated(game))
            .sort(this._gameSort)
            .forEach(gameInfo => this._gameListView.appendGame(gameInfo));
    }

    /**
     * @param gameInfo
     * @returns simple gameInfo object suitable for list population
     */
    _toGameAnnotated(gameInfo: Game): GameAnnotated {
        this.log.info(this.constructor.name, '._toGameAnnotated()');
        var minTurnIndex = Math.min.apply(null, gameInfo.lastPlayedTurnIndexList);

        if (!Array.isArray(gameInfo.playerList)) throw new Error('_toGameAnnotated() - invalid gameInfo: playerList is not an array');
        if (!this._remoteProxy) throw new Error('_toGameAnnotated() - remoteProxy not set');

        var localPlayerIndex = -1;
        var localPlayer: Player | null = null;
        var opponentPlayer: Player = GameListController.UNKNOWN_PLAYER;
        gameInfo.playerList.forEach((player: Player, index: number) => {
            if (player.playerId === this._remoteProxy?.playerId) {
                localPlayer = player;
                localPlayerIndex = index;
            } else {
                opponentPlayer = player;
            }
        });
        if (!localPlayer) {
            throw new Error(`populateGameList() - local player ${this._remoteProxy.playerId} not found in game ${gameInfo.gameId}`);
        }

        const ourTurnIndex : number = gameInfo.lastPlayedTurnIndexList[localPlayerIndex];
        var isOurTurn = (ourTurnIndex === minTurnIndex);

        var whoseTurnText = "";
        if (ourTurnIndex === GameController.END_OF_GAME) {
            whoseTurnText = "Game Ended";
            minTurnIndex = -2;
        } else if (opponentPlayer === GameListController.UNKNOWN_PLAYER) {
            whoseTurnText = "Waiting for player to join";
        } else {
            whoseTurnText = (isOurTurn ? "Your turn" : "Opponent's turn");
        }

        return {
            gameId: gameInfo.gameId,
            turnNumber: minTurnIndex + 2,
            isOurTurn: isOurTurn,
            whoseTurnText,
            opponentPlayer,
        }
    }

    _gameSort(a: any, b: any) {
        if (a.isOurTurn != b.isOurTurn) {
            return (a.isOurTurn ? -1 : 1);
        } else if (a.turnNumber != b.turnNumber) {
            return (a.turnNumber < b.turnNumber ? -1 : 1);
        } else {
            return (a.gameId < b.gameId ? -1 : 1);
        }
    }

    /**
     * Handles the GameListController.GAME_SELECTED event.
     * @param gameId - The ID of the selected game.
     */
    handleGameSelected(gameId: string) {
        console.log(this.constructor.name, '.handleGameSelected', gameId);
        this.emit(GameListController.GAME_SELECTED, gameId);
    }

    handleJoinGame() {
        console.log(this.constructor.name, 'handleJoinGame()');
        this.emit(GameListController.JOIN_GAME);
    }

    handlePromptNewGame() {
        console.log(this.constructor.name, 'handlePromptNewGame()');
        this.emit(GameListController.PROMPT_NEW_GAME);
    }

    // periodic polling function
    _poll() {
        this.log.debug(this.constructor.name, `._poll visible: ${this._gameListView.getVisibility()}`);
        const LONG_POLL_MS = 30 /* seconds */ * 1000;

        if (this._gameListView.getVisibility()) {
            this.getGames()
        }
        setTimeout(() => this._poll(), LONG_POLL_MS);
    }

}
