// enforce given min- and max-Value
import { log } from '../../lib/log';
import { Play } from '../../Play';
import { GamePlayStrategy } from '../GamePlayStrategy';

export class MinMaxAttackMultiplierStrategy implements GamePlayStrategy {
    private log = log.getLogger(this.constructor.name);
    private _minValue: number;
    private _maxValue: number;

    constructor(minValue: number, maxValue: number) {
        this.log.setLevel(log.levels.SILENT)
        this._minValue = minValue;
        this._maxValue = maxValue;
    }

    execute(plays: Play[], _args: any): boolean {
        plays.forEach(play => {
            if (null == play.endAttackMultiplier) throw new Error("MinMaxAttackMultiplierStrategy.execute() endAttackMultiplier is null")
            if (play.endAttackMultiplier > this._maxValue) {
                play.endAttackMultiplier = this._maxValue;
            } else if (play.endAttackMultiplier < this._minValue) {
                play.endAttackMultiplier = this._minValue;
            }
        })
        return true;
    }
}
