import { AttackPlay, AttackRangeStrategy } from './AttackRangeStrategy';
import { Coordinates } from '../Coordinates';

// attack is in-range if the last letter is within 'attackRange' cells of the opponent
export class ConeAttackRangeStrategy extends AttackRangeStrategy {

	radiansOfFire: number; // the angle from the word direction in which an attack is in range

	static ANGLE_FUDGE_FACTOR = 1.01; // broaden the angle slightly to account for rounding errors
	static LENGTH_FUDGE_FACTOR = 1.1; // lengthen the range slightly to account for rounding errors

	constructor(cellSideCount: 6 | 4, degreesOfFire: number) {
		super(cellSideCount);
		this.radiansOfFire = Math.abs(degreesOfFire * ConeAttackRangeStrategy.ANGLE_FUDGE_FACTOR * Math.PI / 180.0);
	}

	// from the commonVertex calculate the angle between the word direction and the target
	absAngleTo( commonVertex: Coordinates, wordEndVertex: Coordinates, targetVertex: Coordinates ): number {
		let angle = 0;
		let a = commonVertex.distanceTo( wordEndVertex, this.cellSideCount )
		let b = commonVertex.distanceTo( targetVertex, this.cellSideCount )
		let c = wordEndVertex.distanceTo( targetVertex, this.cellSideCount )

		angle = Math.acos( (a*a + b*b - c*c) / (2*a*b) );

		return Math.abs(angle);
	}

	// from the commonVertex with a triangle side to the wordEndVertex, 
	// calculate the hypotenuse of a right triangle as the attack range
	hypotenuse( includedAngle: number, side: number ): number {
		let hypotenuse = 0;
		hypotenuse = side / Math.cos(includedAngle);
		return hypotenuse;
	}

    isAttackInRange(attackerPlay: AttackPlay, defenderCoordinates : Coordinates): boolean {
		this.log.info(this.constructor.name, '.isAttackInRange(..)');
		if ( ! super.isAttackInRange(attackerPlay, defenderCoordinates) ) return false;
		if ( attackerPlay.endWordCoords == null ) throw new Error('attackerPlay.endWordCoords is null')

		// calculate angle between word direction and defender, from attacker
		let atttackAngle = this.absAngleTo( attackerPlay.startCoords, attackerPlay.endWordCoords, defenderCoordinates );
		if ( atttackAngle > this.radiansOfFire ) return false;

		let attackRange = ConeAttackRangeStrategy.LENGTH_FUDGE_FACTOR *
			this.hypotenuse(atttackAngle, attackerPlay.startCoords.distanceTo(attackerPlay.endWordCoords, this.cellSideCount));
		if ( attackRange < attackerPlay.startCoords.distanceTo(defenderCoordinates, this.cellSideCount) ) return false;

		return true;
	}
}
